import gql from 'graphql-tag';

export const itemStopQuery = gql`query itemStops($id: ID!) { 
  itemStop(id: $id){
    action {
      id
      name
    }
    additionalInfoEn
    additionalInfoFr
    value
    globalCheck
    globalCheckLink
    hasDeviation
    startDate
    reference
    priority
    docRef
    aircraftPart {
      id
      serialNumber
      partModel {
        id
        reference
        designation
        type
        typeRaw
        category {
          id
          name
        }
        ata {
          id
          chapter
        }
      }
    }
    documentProcessing {
      id
      document {
        id
        number
        title
      }
    }
    tcHolders {
      id
      name
    }
    createdAt
    id
    manufacturerDocRef
    nextActionDate
    stop {
      id
      name
      value
    }
    updatedAt
    remainingText
    usedText
    hasDeviation
  }
}`