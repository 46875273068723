import gql from 'graphql-tag';

export const lastAircraftsQuery = gql`
query LastAircraft($limit: Int) {
  lastAircrafts(limit: $limit) {
    id
    immatriculation
    cell {
      id
      partModel {
        id
        reference
        tcHolder {
          id
          name
        }
      }
    }
    contact {
      id
      name
    }
    aircraftContracts {
      id
      start
      closedAt
    }
  }
}`
