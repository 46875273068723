<template>
  <v-container fluid>
    <v-sheet>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :items="itemStops"
        :headers="header"
        :items-per-page="-1"
        :loading="isLoading"
        loading-text="Y a beaucoup de lignes... merci de patienter"
        dense
        locale="fr-FR"
        no-results-text="Aucun résultat"
      >
        <template v-slot:item.aircraftPart.partModel.category.name="{ item }">
          <div
            class="text-truncate"
            style="max-width: 150px"
            v-if="
              item.aircraftPart &&
              item.aircraftPart.partModel &&
              item.aircraftPart.partModel.category
            "
          >
            {{ item.aircraftPart.partModel.category.name }}
          </div>
        </template>
        <template v-slot:item.remainingText="{ item }">
          <span v-html="item.remainingText"> </span>
        </template>
        <template v-slot:item.usedText="{ item }">
          <span v-html="item.usedText"> </span>
        </template>
        <template v-slot:item.tcHolders="{ item }">
          <span
            class="text-truncate"
            style="width: 150px; display: block"
            v-for="tcHolder in item.tcHolders"
            :key="tcHolder.id"
            >{{ tcHolder.name }}</span
          >
        </template>
        <template v-slot:item.action.name="{ item }">
          <span>{{ item.action.name }} {{ item.additionalInfoFr }}</span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.v-alert {
  margin-bottom: 0;
}
.v-data-table >>> .v-data-table-header {
  background-color: #b9e0e2 !important;
}
</style>

<script>
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";

export default {
  name: "dataTablePieces",
  components: { },
  data() {
    return {
      itemStops: [],
      isLoading: true,
      documentProcessings: null,
      search: null,
      saveLoading: false,
      header: [
        {
          text: "IMMAT",
          value: "",
          width: "70px",
        },
        {
          text: "ATA",
          value: "aircraftPart.partModel.ata.chapter",
          width: "70px",
        },
        {
          text: "TcHolder",
          value: "tcHolders",
        },
        {
          text: "Description",
          value: "aircraftPart.partModel.designation",
          width: "200px",
        },
        {
          text: "P/N ou numéro document",
          value: "reference",
          width: "150px",
        },
        {
          text: "S/N",
          value: "aircraftPart.serialNumber",
        },
        { text: "Butée", value: "stop.name" },
        { text: "Action", value: "action.name", width: "200px" },
        {
          text: "Pot. Effectué",
          value: "usedText",
          width: "150px",
        },
        {
          text: "Pot Restant",
          value: "remainingText",
          width: "200px",
        },
      ],
    };
  },
  methods: {

  }, 
  computed: {

  },

  apollo: {
    itemStops: {
      query: itemStopsQuery,
      
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>