import gql from 'graphql-tag';

export const partModelsQuery = gql`query PartModels($serie: Boolean, $type: [String], $tcHolderIds: [ID]) {
  partModels(serie: $serie, type: $type, tcHolderIds: $tcHolderIds) {
    id
    reference
    type
    serie
    designation
    fullName
    cellType
    tcHolder {
      id
      name
      manufacturers {
        id
        name
      }
    }
    partSerie {
      id
      reference
    }
    category {
      id
      name
    }
    ata {
      id
      chapter
      name
    }
    templateAircrafts {
      id
      name
    }
  }
}`