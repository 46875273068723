<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{}">
      <v-btn color="yellow" @click="dialog = true">initialiser</v-btn>
    </template>
    <v-card>
      <v-card-title>Initialiser</v-card-title>
      <v-card-text class="pt-4">
        <v-container>
          <v-row>
            <v-col cols="6" offset="3">
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="datePicker"
                    label="Date d'initialisation"
                    prepend-icon="mdi-calendar-month"
                    v-on="on"
                    outlined
                    dense
                    readonly
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="start"
                  :first-day-of-week="1"
                  locale="fr"
                  color="green"
                  no-title
                  @input="menu = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-if="aircraft.cell.partModel.templateAircrafts.length > 0">
            <v-col cols="6" offset="3">
              <v-autocomplete
                v-model="template"
                :items="
                  aircraft.cell.partModel.templateAircrafts
                    .map(function (t) {
                      return {
                        text: t.name,
                        value: t.id,
                      };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
                label="Template"
                outlined
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="cleanDialog()">
                Annuler
              </v-btn>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn small color="primary" @click="initialiser()">
                Valider
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { initialisationAircraftMutation } from "../../../graphql/aircraft/initialisation_aircraft";

export default {
  name: "initialisationDialog",
  props: ["aircraft"],
  components: {},
  data() {
    return {
      dialog: false,
      template: null,
      start: null,
      menu: false,
    };
  },
  computed: {
    datePicker() {
      return this.formatDate(this.start);
    },
  },
  methods: {
    cleanDialog() {
      this.dialog = false;
      this.start = null;
      this.template = null;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    initialiser() {
      this.$apollo
        .mutate({
          mutation: initialisationAircraftMutation,
          variables: {
            input: {
              id: parseInt(this.aircraft.id, 10),
              start: this.start,
              template: parseInt(this.template, 10),
            },
          },
        })
        .then(() => {
          this.$emit("refreshPage");
          this.cleanDialog();
        });
    },
  },
};
</script>

