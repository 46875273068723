<template>
  <v-container>
    <v-sheet outlined style="padding-bottom: 30px">
      <v-card-title class="headline text-uppercase">
        <div v-if="aircraft">
          <div class="primary--text" v-if="aircraftPart">
            Modifier pièce pour
            <span style="font-weight: bold; color: red">
              {{ aircraft.immatriculation }}
            </span>
          </div>
          <div v-else class="primary--text">
            Nouvel pièce pour
            <span style="font-weight: bold; color: red">{{
              aircraft.immatriculation
            }}</span>
          </div>
        </div>
      </v-card-title>
      <v-divider style="padding-bottom: 30px"></v-divider>
      <v-row>
        <v-col cols="6">
          <v-sheet class="pt-4">
            <v-form>
              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-select
                    dense
                    outlined
                    v-model="type"
                    prepend-icon="mdi-airplane-cog"
                    label="Quel type de pièce voulez-vous ajouter ?"
                    :items="typeItems"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="type == 'Equipment' || type == 'Part'">
                <v-col cols="10" offset="1" class="py-0">
                  <v-select
                    v-model="attach"
                    dense
                    outlined
                    label="A quelle entité la pièce est rattachée ?"
                    prepend-icon="mdi-airplane"
                    :items="attachItems"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-autocomplete
                    :items="
                      partModels
                        .map(function (t) {
                          if (t.tcHolder) {
                            text =
                              t.tcHolder.name.substring(0, 20) +
                              (t.designation ? ' - ' + t.designation : '') +
                              (t.reference ? ' - ' + t.reference : '');
                          } else {
                            text =
                              t.designation +
                              (t.reference ? ' - ' + t.reference : '');
                          }
                          return {
                            text: text,
                            value: t.id,
                          };
                        })
                        .sort((a, b) => a.text.localeCompare(b.text))
                    "
                    attach
                    small-chips
                    label="Indiquer le Modèle"
                    dense
                    prepend-icon="mdi-alphabetical"
                    v-model="partModelId"
                    outlined
                  ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" offset="1" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="serialNumber"
                    counter="250"
                    label="Numéro de série"
                    prepend-icon="mdi-numeric"
                  ></v-text-field>
                </v-col>
                <v-col cols="5" class="py-0">
                  <v-text-field
                  prepend-icon="mdi-calendar-month"
                  dense
                  outlined
                  v-model="manufacturingDate"
                  label="Dernière intervention"
                  hint="Modèle : 01/12/2021"
                  required
                  @change="$v.manufacturingDate.$touch()"
                  @blur="$v.manufacturingDate.$touch()"
                  :error-messages="manufacturingDateErrors"
                ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="5" offset="1" class="py-0">
                  <v-select
                    dense
                    outlined
                    label="Choississez une position ?"
                    prepend-icon="mdi-car-seat"
                    v-model="position"
                    :items="positionItems"
                  ></v-select>
                </v-col>
                <v-col cols="5" class="py-0">
                  <v-select
                    dense
                    outlined
                    label="Quel est le constructeur ?"
                    v-model="manufacturer"
                    clearable
                    prepend-icon="mdi-home-account"
                    v-if="partModel && partModel.tcHolder"
                    :items="
                      partModel.tcHolder.manufacturers
                        .map(function (m) {
                          return { text: m.name, value: m.id };
                        })
                        .sort((a, b) => a.text.localeCompare(b.text))
                    "
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="partModel">
                <v-col cols="10" offset="1" class="py-0">
                  <v-select
                    prepend-icon="mdi-bookshelf"
                    outlined
                    dense
                    label="Selectionnez le(s) manuel(s)"
                    :multiple="true"
                    v-model="manuals"
                    :items="
                      partModel.manuals
                        .map(function (t) {
                          return {
                            text: t.number + ' - ' + t.title,
                            value: t.id,
                          };
                        })
                        .sort((a, b) => a.text.localeCompare(b.text))
                    "
                    no-data-text="Aucun manuel disponible"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10" offset="1" class="py-0">
                  <v-textarea
                    v-model="notes"
                    dense
                    outlined
                    rows="3"
                    label="Notes"
                    single-line
                    auto-grow
                    prepend-icon="mdi-file-edit-outline"
                  ></v-textarea>
                </v-col>
              </v-row>

            </v-form>
          </v-sheet>
        </v-col>
        <v-col cols="6">
          <v-sheet>
            <v-form>
              <fieldset>
              <legend class="legend1">Valeurs d'initialisation de la pièce</legend>
              <v-row>
                <v-col cols="4" class="pt-6 pr-0 pl-5">
                  <v-text-field
                    label="Heures totales"
                    requis
                    dense
                    outlined
                    prepend-icon="mdi-airplane-clock"
                    v-model="initHours"
                  >
                  </v-text-field>
                </v-col>
                <span class="pt-6 pa-2">:</span>
                <v-col cols="2" class="pt-6 pl-0">
                  <v-text-field
                    label="minutes "
                    requis
                    dense
                    outlined
                    v-model="initMinutes"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" class="pt-6">
                  <v-text-field
                    dense
                    outlined
                    v-model="initCycle"
                    label="Cycle initiale"
                    prepend-icon="mdi-airplane-check"
                  ></v-text-field>
                </v-col>
                <v-col cols="10" class="pt-0 pl-6">
                  <v-checkbox
                  class="mt-0"
                    v-model="noTotalHours"
                    label="Les heures totales de la pièce sont inconnues"
                    :true-value="true"  
                    :false-value="false"
                  ></v-checkbox>
                </v-col>
              </v-row>
              </fieldset>
              <v-row>
                <v-col cols="11" class="py-0 ml-4">
                  <v-radio-group
                    dense
                    v-model="independantHour"
                    row
                    class="mt-0"
                  >
                    <div class="mr-8">
                      Les heures/cycles sont-elles indépendantes ?
                    </div>
                    <v-radio color="#1871ce" label="Oui" :value="true">
                    </v-radio>
                    <v-radio color="#1871ce" label="Non" :value="false">
                    </v-radio
                  ></v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" offset="1" class="ma-6" v-if="aircraftPart">
                  <v-list><ajout-doc :aircraftPart="aircraftPart"></ajout-doc>
                    <v-list-item
                      v-for="document in aircraftPart.aircraftPartDocuments"
                      :key="document.id"
                      class="pl-0"
                    >
                      <v-list-item-icon>
                        <v-icon>mdi-file-document-plus-outline</v-icon>
                      </v-list-item-icon>
                      <template v-if="document.documentAttachment">
                        <v-btn
                          color="primary"
                          dark
                          outlined
                          small
                          target="_blank"
                          :href="document.documentAttachment.fileUrl"
                          >{{ document.name }}
                          <v-icon right dark
                            >mdi-file-pdf-box-outline</v-icon
                          ></v-btn
                        >
                      </template>
                      <ajout-doc
                        :aircraftPart="aircraftPart"
                        :aircraftPartDocument="document"
                      ></ajout-doc>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-form>
          </v-sheet>
        </v-col>

        <v-col cols="12" align="center" class="pt-8">
          <v-btn
            class="mr-4 btn--text"
            outlined
            depressed
            v-if="aircraft && !aircraftPart"
            :to="{
              name: 'aeronef',
              params: {
                immatriculation: aircraft.immatriculation,
                page: 'pieces',
              },
            }"
            >Annuler</v-btn
          >
          <v-btn
            class="mr-4 btn--text"
            outlined
            depressed
            v-if="aircraft && aircraftPart" 
            :to="{
              name: 'aeronef_part',
              params: {
                immatriculation: aircraft.immatriculation,
                id: aircraftPart.id,
              },
            }"
            >Annuler</v-btn
          >
          <v-btn
            class="mr-4 primary primary-text"
            dark
            v-if="aircraftPart"
            @click="create()"
            >Mettre à jour</v-btn
          >
          <v-btn class="mr-4 primary primary-text" dark v-else @click="create()"
            >Créer</v-btn
          >
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<style scoped>

fieldset {
  background-color: #ffffff;
  max-width: 500px;
  margin-top: 0px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.legend1 {
  color: grey;
  margin-bottom: 0px;
  margin-left: 16px;
}
</style>
<script>
import { aircraftQuery } from "@/graphql/aircraft/aircraft";
import { partModelsQuery } from "@/graphql/part_model/part_models";
import { partModelQuery } from "@/graphql/part_model/part_model";
import { createAircraftPartMutation } from "@/graphql/aircraft_part/create_aircraft_part";
import { updateAircraftPartMutation } from "@/graphql/aircraft_part/update_aircraft_part";
import { aircraftPartsQuery } from "@/graphql/aircraft_part/aircraft_parts";
import { aircraftPartQuery } from "@/graphql/aircraft_part/aircraft_part";
import AjoutDoc from "./AjoutDoc.vue";
import { helpers } from "vuelidate/lib/validators";
import moment from "moment";

const dateValidator = helpers.regex("dateValidator", /^\d{2}\/\d{2}\/\d{4}$/)

export default {
  components: {
    AjoutDoc,
  },
  data() {
    return {
      type: null,
      partModelId: null,
      partModel: null,
      partModels: [],
      manuals: [],
      serialNumber: null,
      manufacturer: null,
      manufacturingDate: null,
      initMinutes: null,
      initHours: null,
      initCycle: null,
      independantHour: false,
      attach: null,
      notes: null,
      aircraftParts: [],
      uploadedFileIDs: [],
      noTotalHours: false,
      typeItems: [
        { text: "Moteur", value: "Motor" },
        { text: "Hélice", value: "Propeller" },
        { text: "Equipement (avec TcHolder)", value: "Equipment" },
        { text: "Pièce (sans TcHolder)", value: "Part" },
      ],
      position: null,
      positionItems: [
        { text: "", value: null },
        { text: "Avant", value: "Avant" },
        { text: "Milieu", value: "Milieu" },
        { text: "Arrière", value: "Arrière" },
        { text: "droite", value: "droite" },
        { text: "gauche", value: "gauche" },
        { text: "pilote", value: "pilote" },
        { text: "co-pilote", value: "co-pilote" },
        { text: "passager", value: "passager" },
      ],
    };
  },
  mounted() {
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
  },
  validations() {
    let val = {
        manufacturingDate: { dateValidator },
      };

      return val;
  },
  methods: {
    create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      if (this.aircraftPart) {
        this.$apollo
          .mutate({
            mutation: updateAircraftPartMutation,
            variables: {
              input: {
                id: parseInt(this.aircraftPart.id, 10),
                attributes: {
                  serialNumber: this.serialNumber,
                  partModelId: this.partModelId,
                  manufacturingDate: this.manufacturingDateSql,
                  flyValue: this.calcFlyValue,
                  independantHour: this.independantHour,
                  manufacturerId: this.manufacturer,
                  attachId: this.attach,
                  notes: this.notes,
                  formOne: this.uploadedFileIDs,
                  position: this.position,
                  manualsData: this.manuals.map((ma) => parseInt(ma, 10)),
                  noTotalHours: this.noTotalHours,
                },
              },
            },
          })
          .then((data) => {
            this.$router.push({
              name: "aeronef_part",
              params: {
                immatriculation: this.aircraft.immatriculation,
                id: data.data.updateAircraftPart.aircraftPart.id,
              },
            });
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftPartMutation,
            variables: {
              input: {
                attributes: {
                  serialNumber: this.serialNumber,
                  aircraftId: this.aircraft.id,
                  partModelId: this.partModelId,
                  manufacturingDate: this.manufacturingDateSql,
                  manufacturerId: this.manufacturer,
                  flyValue: this.calcFlyValue,
                  independantHour: this.independantHour,
                  attachId: this.attach,
                  notes: this.notes,
                  formOne: this.uploadedFileIDs,
                  position: this.position,
                  manualsData: this.manuals.map((ma) => parseInt(ma, 10)),
                  noTotalHours: this.noTotalHours,
                },
              },
            },
          })
          .then((data) => {
            this.$router.push({
              name: "aeronef_part",
              params: {
                immatriculation: this.aircraft.immatriculation,
                id: data.data.createAircraftPart.aircraftPart.id,
              },
            });
          });
      }
      }
    },
    totalHours(hours, minutes) {
      if (!hours && !minutes) {
        return null;
      }
      var totalMinutes = 0;
      if (hours) {
        totalMinutes += parseInt(hours, 10) * 60;
      }
      if (minutes) {
        totalMinutes += parseInt(minutes, 10);
      }
      return totalMinutes;
    },
    setHoursAndMinutes(total) {
      const minutes = total % 60;
      const hours = Math.floor(total / 60);
      return {
        hours: hours,
        minutes: minutes,
      };
    },
    setFlyValue(flyValue) {
      let cycle = flyValue["init"].find((v) => v["unit_code"] == "cycle");
      if (cycle) {
        this.initCycle = cycle["value"];
      }
      let hours = flyValue["init"].find((v) => v["unit_code"] == "hours");
      if (hours) {
        console.log(hours);
        const h = this.setHoursAndMinutes(hours["value"]);
        this.initMinutes = h["minutes"];
        this.initHours = h["hours"];
      }
    },
  },
  computed: {
    manufacturingDateSql: function () {
      if (this.manufacturingDate) {
        const convDate = this.manufacturingDate.trim().split("/");
        return convDate[2] + "-" + convDate[1] + "-" + convDate[0];
      }
      return null;
    },    
    manufacturingDateErrors() {
      const errors = [];
      if (!this.$v.manufacturingDate.$dirty) return errors;
      !this.$v.manufacturingDate.required &&
        errors.push("La date est manquante");
      !this.$v.manufacturingDate.dateValidator &&
        errors.push("La date n'est pas au bon format");
      return errors;
    },
    calcFlyValue() {
      var h = { init: [], current: [] };
      h["init"].push({
        value: this.totalHours(this.initHours, this.initMinutes),
        unit_code: "hours",
      });
      h["init"].push({
        value: parseInt(this.initCycle, 10),
        unit_code: "cycles",
      });
      return h;
    },
    attachItems() {
      var items = [{ text: "Sans Attache", value: null }];
      if (this.aircraftParts) {
        this.aircraftParts.map(function (ap) {
          var text = ap.partModel.category.name;
          if (ap.position) {
            text = text + " (" + ap.position + ")";
          }
          text =
            text +
            " - " +
            ap.partModel.reference +
            " (s/n: " +
            ap.serialNumber +
            ")";
          items.push({
            text: text,
            value: ap.id,
          });
        });
      }
      return items;
    },
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
    aircraftPart: {
      query: aircraftPartQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      skip() {
        return !this.$route.params.id;
      },
      result({ data }) {
        if (data) {
          this.type = data.aircraftPart.partModel.typeRaw;
          this.serialNumber = data.aircraftPart.serialNumber;
          this.partModelId = data.aircraftPart.partModel.id.toString();
          this.manuals = data.aircraftPart.manualsData.map((ma) =>
            ma.toString()
          );
          this.independantHour = data.aircraftPart.independantHour;
          if (data.aircraftPart.manufacturingDate) {
            this.manufacturingDate = moment(data.aircraftPart.manufacturingDate).format(
              "DD/MM/YYYY"
            );
          }  
          if (data.aircraftPart.attach) {
            this.attach = data.aircraftPart.attach.id;
          }
          if (data.aircraftPart.manufacturer) {
            this.manufacturer = data.aircraftPart.manufacturer.id;
          }
          this.notes = data.aircraftPart.notes;
          this.position = data.aircraftPart.position;
          this.uploadedFileIDs = data.aircraftPart.formOne.map(
            (fo) => fo.signedId
          );
          this.setFlyValue(data.aircraftPart.flyValue);
          this.noTotalHours = data.aircraftPart.noTotalHours;
        }
      },
    },
    partModels: {
      query: partModelsQuery,
      variables() {
        return { tcHolderId: null, type: this.type, serie: false };
      },
      skip() {
        return !this.type;
      },
    },
    partModel: {
      query: partModelQuery,
      variables() {
        return { id: parseInt(this.partModelId, 10) };
      },
      skip() {
        return !this.partModelId;
      },
    },
    aircraftParts: {
      query: aircraftPartsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          type: ["Cell", "Motor", "Propeller"],
        };
      },
      skip() {
        return !this.type || !this.aircraft;
      },
    },
  },
};
</script>
