<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    v-if="documentProcessing"
  >
    <template v-slot:activator="{ on: dialog, attrs }">
      <v-tooltip top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn icon v-if="process === null">
            <v-icon v-on="{ ...tooltip, ...dialog }" color="red" v-bind="attrs"
              >mdi-help-circle-outline</v-icon
            >
          </v-btn>
          <v-btn icon v-else color="green">
            <v-icon v-on="{ ...tooltip, ...dialog }" dark v-bind="attrs"
              >mdi-check-circle</v-icon
            >
          </v-btn>
        </template>
        <span>Document Traité</span>
      </v-tooltip>
    </template>
    <v-card>
      <v-card-text class="pt-4">
        <div v-if="aircraft">
          <v-row>
            <v-col cols="6">
              <p class="text-h5 primary--text">TRAITEMENT</p>
              <p>
                <span class="text-h6">{{ aircraft.immatriculation }}</span>
                <span >
                  <v-btn
                    text
                    link
                    small
                     target="_blank"
                    :to="{
                      name: 'aeronef',
                      params: {
                        immatriculation: aircraft.immatriculation,
                        page: 'pieces',
                      },
                    }"
                    >( voir pièces )</v-btn
                  >
                </span>
              </p>
              <p class="text-h7">
                {{ aircraft.cell.partModel.tcHolder.name }}
                <span class="font-weight-bold">{{
                  aircraft.cell.partModel.reference
                }}</span>
                ( s/n :
                <span class="font-weight-bold">{{
                  aircraft.cell.serialNumber
                }}</span>
                ) - fabriqué le
                <span
                  class="font-weight-bold"
                  v-if="aircraft.cell.manufacturingDate"
                >
                  {{
                    aircraft.cell.manufacturingDate | moment("DD/MM/YYYY")
                  }}</span
                >
              </p>
              <p class="text-h7" v-if="aircraft.motors.length > 0">
                {{ aircraft.motors[0].partModel.tcHolder.name }}
                <span class="font-weight-bold">
                  {{ aircraft.motors[0].partModel.reference }}</span
                >
                ( s/n :
                <span class="font-weight-bold">
                  {{ aircraft.motors[0].serialNumber }}</span
                >
                )
              </p>
              <p class="text-h7" v-if="aircraft.propellers.length > 0">
                {{ aircraft.propellers[0].partModel.tcHolder.name }}
                <span class="font-weight-bold">{{
                  aircraft.propellers[0].partModel.reference
                }}</span>
                ( s/n :
                <span class="font-weight-bold">{{
                  aircraft.propellers[0].serialNumber
                }}</span>
                )
              </p>
            </v-col>
            <v-col cols="6">
              <v-row justify="center" class="mt-16">
                <template v-if="documentProcessing.document.mandatory === true  || documentProcessing.document.type == 'Ad'">
                  <v-chip
                    dense
                    outlined
                    disable
                    small
                    color="red"
                    >MANDATORY</v-chip
                  >
                </template>
                <template v-if="documentProcessing.document.recurring === true">
                  <v-chip
                    dense
                    outlined
                    disable
                    small
                    color="green"
                    >REPETITIVE</v-chip
                  >
                </template>
              </v-row>
              <v-row justify="center">
                <v-btn
                  class="mt-4"
                  outlined
                  small
                  color="red"
                  target="_blank"
                  :href="documentProcessing.document.documentFile"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>
                  {{ documentProcessing.document.number }}
                  <template v-if="documentProcessing.document.revision">{{
                    documentProcessing.document.revision
                  }}</template>
                  <span style="max-width: 250px" class="text-truncate"
                    >- {{ documentProcessing.document.title }}</span
                  >
                </v-btn>
              </v-row>
              <v-row justify="center" v-if="documentProcessing.document.sbLink .length > 0">
                <v-btn
                  class="mt-4"
                  outlined
                  color="orange darken-2"
                  target="_blank"
                  small
                  v-for="sb in documentProcessing.document.sbLink"
                    :key="sb.id"
                  :href="sb.documentFile"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>lié au
                  {{ sb.numberRev }}
                  
                </v-btn>
              </v-row>
               <v-row justify="center" v-if="documentProcessing.document.sbLink .length > 0">
                <v-btn
                  class="mt-4"
                  outlined
                  color="orange darken-2"
                  target="_blank"
                  small
                  v-for="sb in documentProcessing.document.sbLink"
                    :key="sb.id"
                  :href="sb.documentFile"
                >
                  <v-icon left>mdi-file-pdf-box</v-icon>lié au
                  {{ sb.numberRev }}
                  
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8" offset="2" class="mt-4">
              <v-select
                dense
                v-model="process"
                label="Quel traitement voulez-vous appliquer ?"
                :items="typeItems"
                @change="fillProcess()"
              >
              </v-select>
            </v-col>
          </v-row>

          <template v-if="process == 'stop'">
            <v-row>
              <v-col cols="12">
                <v-card-title>
                  <item-stop-form
                    :parentDocumentProcessingId="
                      parseInt(documentProcessing.id, 10)
                    "
                    @change_item_stops="refreshItemStops()"
                  ></item-stop-form>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="itemStops"
                  :items-per-page="18"
                  dense
                  hide-default-footer
                  no-results-text="Aucun résultat"
                >
                  <template v-slot:item.stop.action="{ item }">
                    <item-stop-form
                      :itemStopId="parseInt(item.id, 10)"
                      @change_item_stops="refreshItemStops()"
                    ></item-stop-form>
                  </template>
                  <template v-slot:item.value="{ item }">
                    {{ item.value }}
                  </template>
                  <template v-slot:item.action.name="{ item }">
                    {{ item.action.name }} {{ item.additionalInfoFr }}
                  </template>
                  <template v-slot:item.usedText="{ item }">
                    <span v-html="item.usedText"> </span>
                  </template>
                  <template v-slot:item.remainingText="{ item }">
                    <span v-html="item.remainingText"> </span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </template>

          <template v-if="process == 'apply'">
            <v-row>
              <v-col cols="8" offset="2">
                <v-menu
                  v-model="menuDate"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="datePicker"
                      label="Date de l'application"
                      prepend-icon="mdi-calendar-month"
                      outlined
                      dense
                      readonly
                      v-on="on"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="applyDate"
                    :first-day-of-week="1"
                    locale="fr"
                    color="green"
                    no-title
                    @input="menuDate = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-text-field
                  label="Référence de l'OT ou atelier"
                  v-model="applyNumber"
                ></v-text-field>
              </v-col>
            </v-row>
          </template>

          <template v-if="process == 'noted'">
            <v-row>
              <v-col cols="8" offset="2">
                <v-textarea
                  dense
                  rows="1"
                  label="Information prise en compte par SEGA et transmise aux personnes concernées"
                  placeholder="completez si besoin"
                  auto-grow
                  v-model="detailsFr"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-textarea
                  dense
                  rows="1"
                  label="Information taken into account by SEGA and communicated to the persons concerned"
                  placeholder="fill in if necessary"
                  auto-grow
                  v-model="detailsEn"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </template>

          <template v-if="process == 'not_concerned'">
            <v-row>
              <v-col cols="8" offset="2">
                <v-select
                  :items="detailsList"
                  item-text="value"
                  item-value="id"
                  @change="setDetails()"
                  v-model="detailSelect"
                  placeholder="Choisir la raison"
                  label="Choisir la Raison"
                  outlined
                  dense
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-textarea
                  dense
                  rows="1"
                  label="Détails (français)"
                  auto-grow
                  v-model="detailsFr"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" offset="2">
                <v-textarea
                  dense
                  rows="1"
                  label="Détails (anglais)"
                  auto-grow
                  v-model="detailsEn"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </template>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click="closeDialog()"> Fermer </v-btn>
        <v-btn color="primary" text @click="saveProcessing()"> Valider </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { documentProcessingQuery } from "../../graphql/document_processing/document_processing";
import { updateDocumentProcessingMutation } from "../../graphql/document_processing/update_document_processing";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import { aircraftQuery } from "@/graphql/aircraft/aircraft";
import itemStopForm from "../Butees/itemStopForm.vue";

export default {
  name: "dialogTraitement",
  components: {
    "item-stop-form": itemStopForm,
  },
  props: {
    processingId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      process: null,
      detailsFr: null,
      detailsEn: null,
      detailSelect: null,
      showStop: false,
      menuDate: false,
      applyDate: null,
      applyNumber: null,
      type: "none",
      detailsList: [
        { id: 0, value: "", textFr: "", textEn: "" },
        {
          id: 1,
          value: "par modèle d'aéronef",
          textFr: "Non concerné par modèle d'aéronef",
          textEn: "Non concerned by aircraft's model",
        },
        {
          id: 2,
          value: "par S/N de l'aéronef",
          textFr: "Non concerné par S/N de l'aéronef",
          textEn: "Non concerned by S/N of aircraft",
        },
        {
          id: 3,
          value: "par date de fabrication de l'aéronef",
          textFr: "Non concerné par date de fabrication de l'aéronef",
          textEn: "Non concerned by aircraft's date of fabrication",
        },
        {
          id: 4,
          value: "par type de pièce concernée",
          textFr: "Non concerné par [indiquer la pièce concernée]",
          textEn: "Non concerned by [write the designated part]",
        },
        {
          id: 5,
          value: "par P/N de la pièce concernée",
          textFr: "Non concerné par P/N [indiquer la pièce concernée]",
          textEn: "Non concerned by P/N [write the designated part]",
        },
        {
          id: 6,
          value: "par S/N de la pièce concernée",
          textFr: "Non concerné par S/N [indiquer la pièce concernée]",
          textEn: "Non concerned by S/N [write the designated part]",
        },
        {
          id: 7,
          value: "Non équipée de la pièce concernée",
          textFr: "Non équipé de [indiquer la pièce concernée]",
          textEn: "Non equiped by [write the designated part]",
        },
        {
          id: 8,
          value: "par Atelier cité",
          textFr: "Non concerné par l'atelier mentionné",
          textEn: "Non concerned by workshop mentionned",
        },
        {
          id: 9,
          value: "par STC cité",
          textFr: "Non concerné par le STC mentionné]",
          textEn: "Non concerned by STC mentionned",
        },
        {
          id: 10,
          value: "autre raison",
          textFr: "Non concerné par [indiquer la raison]",
          textEn: "Non concerned by [state the reason]",
        },
      ],
      headers: [
        { text: "", value: "stop.action", width: "50px" },
        { text: "Action", value: "action.name" },
        { text: "butée", value: "stop.name" },
        { text: "Pot. Effectué", value: "usedText", width: "200px" },
        { text: "Pot Reste", value: "remainingText", width: "200px" },
      ],
      typeItems: [
        { text: "Ajouter une ou des butées", value: "stop" },
        { text: "Finaliser une action", value: "apply" },
        { text: "Prendre en compte le document", value: "noted" },
        { text: "Mettre en non-concerné", value: "not_concerned" },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    fillProcess() {
      if (this.process == "noted") {
        this.detailsFr = "Information prise en compte";
        this.detailsEn = "Information taken into consideration";
      } else {
        this.detailsFr = "";
        this.detailsEn = "";
      }
    },
    refreshItemStops() {
      this.$apollo.queries.itemStops.refetch();
    },
    setDetails() {
      const ds = this.detailsList.find((l) => l.id == this.detailSelect);
      this.detailsFr = ds.textFr;
      this.detailsEn = ds.textEn;
    },
    saveProcessing() {
      this.$apollo
        .mutate({
          mutation: updateDocumentProcessingMutation,
          variables: {
            input: {
              id: parseInt(this.documentProcessing.id, 10),
              attributes: {
                process: this.process,
                detailsFr: this.detailsFr,
                detailsEn: this.detailsEn,
                processDate: this.applyDate,
                processNumber: this.applyNumber,
              },
            },
          },
        })
        .then(() => {
          this.dialog = false;
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
  computed: {
    datePicker() {
      return this.formatDate(this.applyDate);
    },
  },
  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          documentProcessingId: this.processingId,
        };
      },
    },
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.documentProcessing.aircraft.immatriculation,
        };
      },
      skip() {
        return !this.documentProcessing;
      },
    },
    documentProcessing: {
      query: documentProcessingQuery,
      variables() {
        return {
          id: this.processingId,
        };
      },
      result({ data }) {
        if (data) {
          this.process = data.documentProcessing.process;
          this.detailsFr = this.documentProcessing.detailsFr;
          this.detailsEn = this.documentProcessing.detailsEn;
          if (this.process == "stop") {
            this.showStop = true;
          }
          this.applyDate = this.documentProcessing.processDate;
          this.applyNumber = this.documentProcessing.processNumber;
        }
      },
    },
  },
};
</script>
