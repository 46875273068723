var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',[_c('v-card-title',[_c('v-btn',{staticClass:"mr-4 mb-4",attrs:{"small":"","rounded":"","outlined":"","color":"red lighten-1","target":"_blank","loading":_vm.saveLoading},on:{"click":_vm.generateKardex}},[_c('v-icon',{staticClass:"mr-2",attrs:{"dark":""}},[_vm._v("mdi-file-pdf-box")]),_vm._v("SITUATION TECHNIQUE")],1),_c('projected-dialog',{attrs:{"aircraftId":_vm.aircraftId}}),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('v-switch',{attrs:{"label":"Groupé"},model:{value:(_vm.groupTable),callback:function ($$v) {_vm.groupTable=$$v},expression:"groupTable"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.itemStops,"headers":_vm.header,"items-per-page":-1,"sort-by":[
        'aircraftPart.partModel.ata.chapter',
        'aircraftPart.partModel.designation',
        'tcHolders',
        'reference',
      ],"loading":_vm.isLoading,"group-by":_vm.groupField,"loading-text":"Y a beaucoup de lignes... merci de patienter","dense":"","locale":"fr-FR","no-results-text":"Aucun résultat"},scopedSlots:_vm._u([{key:"group.header",fn:function({ items, isOpen, toggle }){return [_c('th',{staticClass:"btn",attrs:{"colspan":"9"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? "mdi-minus" : "mdi-plus")+" ")]),_c('span',{staticClass:"ml-8",staticStyle:{"text-transform":"uppercase","font-weight":"bold"}},[_vm._v(_vm._s(items[0].aircraftPart ? items[0].aircraftPart.attachGroup : "Documents"))])],1)]}},{key:"item.aircraftPart.partModel.category.name",fn:function({ item }){return [(
            item.aircraftPart &&
            item.aircraftPart.partModel &&
            item.aircraftPart.partModel.category
          )?_c('div',{staticClass:"text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(" "+_vm._s(item.aircraftPart.partModel.category.name)+" ")]):_vm._e()]}},{key:"item.remainingText",fn:function({ item }){return _vm._l((item.remainingText),function(v){return _c('div',{key:v['unit_code']},[(v['text'])?_c('v-chip',{attrs:{"color":_vm.chipColor(v)}},[_vm._v(" "+_vm._s(v["text"])+" ")]):_vm._e(),_c('br')],1)})}},{key:"item.usedText",fn:function({ item }){return _vm._l((item.usedText),function(v){return _c('span',{key:v['unit_code']},[_vm._v(" "+_vm._s(v["text"])+" "),_c('br')])})}},{key:"item.category",fn:function({ item }){return [(item.tcHolders.length > 0)?_c('div',_vm._l((item.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id,staticClass:"text-truncate",staticStyle:{"width":"150px","display":"block"}},[_vm._v(_vm._s(tcHolder.name))])}),0):_c('div',[_vm._v(_vm._s(item.aircraftPart.partModel.category.name))])]}},{key:"item.action.name",fn:function({ item }){return [_c('span',[_vm._v(_vm._s(item.action.name)+" "+_vm._s(item.additionalInfoFr))]),(item.globalCheckLink == true)?_c('span',{staticClass:"blue--text font-weight-bold"},[_vm._v(" (R)")]):_vm._e(),(item.hasDeviation == true)?_c('span',{staticClass:"red--text font-weight-bold"},[_vm._v(" (D)")]):_vm._e()]}},{key:"item.reference",fn:function({ item }){return [(item.reference)?_c('span',[_vm._v(" "+_vm._s(item.reference))]):_c('span',[_vm._v(" Toutes références")])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }