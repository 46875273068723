<template>
  <v-card outlined elevation="0" height="280px" color="colorcard" v-if="me">
    <v-img height="120" src="@/assets/avion_banniere.png"></v-img>
    <v-row style="margin: 2.5%; position: absolute; top: 10px">
      <v-list-item>
        <v-list-item-avatar size="120" tile>
          <v-avatar id="wrapper" size="120" v-if="me">
            <v-avatar size="107" v-if="me">
              <img :src="me.avatar" />
            </v-avatar>
          </v-avatar>
        </v-list-item-avatar>
      </v-list-item>
    </v-row>
    <v-row>
      <v-col cols="5">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title mt-2">{{
              me.name
            }}</v-list-item-title>
            <v-list-item-subtitle>{{ me.title }}</v-list-item-subtitle>
            <v-divider class="ma-2"></v-divider>
            <v-list-item-subtitle>{{ me.aircraftCount }} avions en gestion</v-list-item-subtitle>
          
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="7" align="center">

      </v-col>
    </v-row>
  </v-card>
</template>
      



<style>
.card-header {
  position: relative;
  padding: 2rem 2rem;
  border-bottom: none;
  background-color: white;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
  z-index: 2;
}
#wrapper {
  border-radius: 50%;
  border: 7px solid #ffffff;
}

</style>
<script>
import { meQuery } from "@/graphql/me";

export default {
  props: {},
  components: {},
  data: () => ({
    me: null,
    dateDigits: [],
  }),
  computed: {},
  methods: {
    aircraftCount() {
      // Define the method logic here
      return this.me && this.me.aircrafts ? this.me.aircrafts.length : 0;
    },
    updateDate() {
      const now = new Date();
      const dateString = now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
      this.dateDigits = dateString.split('');
    },
  },
  mounted() {
    this.updateDate();
    setInterval(this.updateDate, 1000);
  },
  apollo: {
    me: {
      query: meQuery,
    },
  },
};
</script>