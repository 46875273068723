<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <sbuttonAdd
              v-if="typeDoc == 'Manual'"
              :to="{ name: 'new_document', query: { type: 'Manual' } }"
              ><v-icon left dark> mdi-plus </v-icon> Nouveau manuel</sbuttonAdd
            >
            <sbuttonAdd
              class="mr-4"
              v-if="typeDoc == 'AdSb'"
              :to="{ name: 'new_document', query: { type: 'Ad' } }"
              ><v-icon left dark> mdi-plus </v-icon> Nouvel AD</sbuttonAdd
            >
            <sbuttonAdd
              v-if="typeDoc == 'AdSb'"
              :to="{ name: 'new_document', query: { type: 'Sb' } }"
              ><v-icon left dark> mdi-plus </v-icon> SB</sbuttonAdd
            >
            <liaison-modal></liaison-modal>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="3" offset="3">
                <v-select
                  label="Statut"
                  :items="docOptions"
                  v-model="docFilter"
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Rechercher"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="documents"
            :footer-props="{
              'items-per-page-options': [20, 50, 100, 500],
              'items-per-page-text': 'lignes par page',
              'items-per-page-all-text': 'Tous',
            }"
            :search="search"
            :loading="isLoading"
            locale="fr-FR"
            fixed-header
            loading-text="Compte à l'envers depuis l'infini"
            dense
            no-data-text="Compte à l'envers depuis l'infini"
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.status="{ item }">
              <v-icon color="green" v-if="item.status == 'active'"
                >mdi-file-check-outline</v-icon
              >
              <v-icon v-if="item.status == 'replace'" color="orange"
                >mdi-file-swap-outline</v-icon
              >
              <v-icon color="red" v-if="item.status == 'cancel'"
                >mdi-file-remove-outline</v-icon
              >
            </template>

            <template v-slot:item.createdAt="{ item }">{{
              item.createdAt | moment("DD/MM/YYYY")
            }}</template>
            <template v-slot:item.publishingDate="{ item }">{{
              item.publishingDate | moment("DD/MM/YYYY")
            }}</template>
            <template v-slot:item.tcHolders="{ item }">
              <v-btn
                v-for="tcHolder in item.tcHolders"
                :key="tcHolder.id"
                text
                :to="{ name: 'tc_holder', params: { id: tcHolder.id } }"
                >{{ tcHolder.name }}</v-btn
              >
            </template>

            -
            <template v-slot:item.documentFile="{ item }">
              <v-btn
                icon
                v-if="item.documentFile"
                :href="item.documentFile"
                target="_blank"
              >
                <v-icon color="red">mdi-file-pdf-box</v-icon>
              </v-btn>
              <v-btn icon v-else>
                <v-icon class="grey--text">mdi-block-helper</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.type="{ item }">
              <span v-if="item.type == 'Ad'">AD</span>
              <span v-if="item.type == 'Sb'">SB</span>
              <span v-if="item.type == 'Manual'">MANUEL</span>
            </template>

            <template v-slot:item.manualType="{ item }">
              <span v-if="item.manualType == 'MM'">Maintenance Manual</span>
              <span v-if="item.manualType == 'IPC'">Parts Catalogs</span>
              <span v-if="item.manualType == 'MV'">Manuel de Vol</span>
              <span v-if="item.manualType == 'IND'">Index</span>
              <span v-if="item.manualType == 'MISC'">Autres Manuels</span>
            </template>

            <template v-slot:item.number="{ item }">
              <v-btn
                text
                small
                target="blank"
                :to="{ name: 'document', params: { id: item.id } }"
                >{{ item.number }}</v-btn
              >
            </template>

            <template v-slot:item.numberRev="{ item }">
              <v-btn
                text
                small
                target="blank"
                :to="{ name: 'document', params: { id: item.id } }"
                >{{ item.numberRev }}</v-btn
              >
            </template>

            <template v-slot:item.revision="{ item }">
              <span
                class="wrap_text"
                style="min-width: 300px"
                v-if="item.type == 'Manual'"
                >{{ item.revision }}</span
              >
            </template>

            <template v-slot:item.title="{ item }">
              <div
                v-if="item.status == 'replace' || item.status == 'cancel'"
                class="wrap_text"
                style="min-width: 400px"
              >
                [ANNULE] {{ item.title }}
              </div>
              <div v-else class="wrap_text" style="min-width: 400px">
                {{ item.title }}
              </div>
            </template>

            <template v-slot:item.documentType="{ item }">
              <div class="wrap_text" style="min-width: 100px">
                {{ item.documentType }}
              </div>
            </template>

            <template v-slot:item.tcHolders[0].name="{ item }">
              <div class="wrap_text" style="min-width: 100px">
                {{
                  item.tcHolders.length > 0
                    ? item.tcHolders[0].name
                    : "TOUS CONSTRUCTEURS"
                }}
              </div>
            </template>

            <template v-slot:item.link="{ item }">                              
              <div v-if="item.type == 'Ad' && item.sbLink.length > 0">                
                <v-btn
                  v-for="sb in item.sbLink"
                  :key="sb.id"
                  text
                  small
                  target="blank"
                  :to="{ name: 'document', params: { id: sb.id } }"
                >
                  {{ sb.numberRev }}
                </v-btn>
              </div>              
              <div v-if="item.type == 'Sb' && item.adLink.length > 0">                
                <v-btn
                  v-for="ad in item.adLink"
                  :key="ad.id"
                  text
                  small
                  target="blank"
                  :to="{ name: 'document', params: { id: ad.id } }"
                >
                  {{ ad.numberRev }}
                </v-btn>
              </div>
            </template>
          </v-data-table>
          <!-- <confirm ref="confirm"></confirm> -->
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.wrap_text {
  word-wrap: break-word;
  white-space: normal;
}
</style>

<script>
import sbuttonAdd from "../../components/sbuttonAdd.vue";
import { documentsQuery } from "../../graphql/document/documents";
import LiaisonModal from "./LiaisonModal.vue";

// import confirm from "@/components/confirm.vue";

export default {
  name: "dataTableDocument",
  components: {
    sbuttonAdd,
    LiaisonModal,
  },
  props: {
    typeDoc: {
      type: String,
      required: true,
    },
  },
  computed: {
    items: function () {
      if (this.typeDoc == "AdSb") {
        return ["Ad", "Sb"];
      }
      if (this.typeDoc == "Manual") {
        return ["Manual"];
      }
      return [];
    },
    typeDocList: function () {
      if (this.typeDoc == "AdSb") {
        return ["Ad", "Sb"];
      }
      if (this.typeDoc == "Manual") {
        return ["Manual"];
      }
      return [];
    },
    headers: function () {
      if (this.typeDoc == "AdSb") {
        return [
          { text: "", value: "documentFile", width: "50px" },
          { text: "AJOUTÉ LE", value: "createdAt", width: "100px" },
          { text: "", value: "status", width: "40px" },
          { text: "", value: "type", width: "40px" },
          { text: "TC HOLDER", value: "tcHolders[0].name", width: "200px" },
          {
            text: "NUMERO",
            align: "start",
            value: "numberRev",
            width: "150px",
          },
          { text: "TITRE", value: "title", width: "500px" },
          { text: "Liaison", value: "link", width: "100px" },
          { text: "Ref US", value: "usDocument", width: "100px" },
        ];
      }
      if (this.typeDoc == "Manual") {
        return [
          { text: "", value: "documentFile", width: "50px" },
          { text: "TYPE", value: "manualType", width: "170px" },
          { text: "STATUS", value: "status", width: "40px" },
          { text: "TC HOLDER", value: "tcHolders[0].name", width: "200px" },
          {
            text: "NUMERO",
            align: "start",
            sortable: true,
            value: "number",
            width: "150px",
          },
          { text: "TITRE", value: "title", sortable: true, width: "30px" },
          { text: "EDITION", value: "version", width: "400px" },
        ];
      }
      if (this.typeDoc == "All") {
        return [
          { text: "", value: "documentFile", width: "50px" },
          { text: "AJOUTÉ LE", value: "createdAt", width: "100px" },
          { text: "TYPE", value: "manualType", width: "170px" },
          { text: "TC HOLDER", value: "tcHolders", width: "150px" },
          {
            text: "NUMERO",
            align: "start",
            sortable: true,
            value: "number",
            width: "100px",
          },
          { text: "TITRE", value: "title", sortable: true, width: "30px" },
        ];
      }
      return [];
    },
  },
  data() {
    return {
      documents: [],
      search: "",
      isLoading: true,
      docFilter: "active",
      docOptions: [
        { text: "Tout", value: "" },
        { text: "En cours", value: "active" },
      ],
    };
  },
  apollo: {
    documents: {
      query: documentsQuery,
      variables() {
        return {
          type: this.typeDocList,
          status: this.docFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
