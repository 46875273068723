<template>
	<v-container>
		<v-col cols="12">
      <v-sheet>
				<v-card-title>
					<template-item-stop-form 
						:templateAircraftPart="templateAircraftPart"
					></template-item-stop-form>
				</v-card-title>
				<v-data-table
					:headers="headers"
					:items="templateAircraftPart.templateItemStops"
					dense
          :items-per-page="-1"
          hide-default-footer
					no-results-text="Aucun résultat"
				>
					<template v-slot:item.action="{ item }">
							<span>{{ item.action.name }} {{ item.additionalInfoFr }}</span>
							<span v-if="item.globalCheckLink == true">(R)</span>
						</template>
						<template v-slot:item.bouton="{  }">

					</template> 
					<template v-slot:item.bouton="{ item }">
            <template-item-stop-form :templateItemStop="item" :templateAircraftPart="templateAircraftPart"
            ></template-item-stop-form>
          </template>
				</v-data-table>
			</v-sheet>
		</v-col>
	</v-container>
</template>
<script>
import TemplateItemStopForm from './TemplateItemStopForm.vue';

export default {
	props: {
		templateAircraftPart: {
			type: Object,
			required: true,
		},
	},
	components: {
		TemplateItemStopForm
	},
	data() {
    return {
			all: false,
      headers: [
        { text: "", value: "bouton", width: "60px" },
        { text: "Butée", value: "stop.name" },
        { text: "Action", value: "action.name" },
      ],
    };
  },
	methods: {

	},	
};

</script>














