import gql from 'graphql-tag';

export const documentFragment = {
    fragments: {
      document: gql`
      fragment document on Document {
        id
        type
        mandatory
        number
        numberRev
        publishingDate
        effectiveDate
        additionalInformation
        recurring
        revision
        documentFile
        documentFileSignedId
        targets
        status
        version
        manualType
        cancelReason
        targetsModels {
          id
          reference
          tcHolder {
            id
            name
          }
        }
        tcHolders {
          id
          name
        }
        titleFr
        titleEn
        title
        equipment
        replace {
          id
          number
          revision
          numberRev
        }
        replaceBy {
          id
          number
          revision
          numberRev
        }
        sbLink {
          id
          numberRev
        }
        adLink {
          id
          numberRev
        }        
        usDocument
        updatedAt
        createdAt
        lastCheckAt
        }` 
    }
}