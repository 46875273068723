var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"8","offset":"2"}},[_c('v-sheet',{staticStyle:{"padding-bottom":"30px"},attrs:{"outlined":""}},[_c('v-form',[_c('v-card-title',{staticClass:"headline text-uppercase"},[_c('span',{staticClass:"primary--text"},[_vm._v("Modifier | Pièce")])]),_c('v-divider',{staticStyle:{"padding-bottom":"50px"}}),_c('v-row',[_c('v-col',{attrs:{"cols":"5","offset":"1"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Quel type celà concerne-t-il ?","items":_vm.typeItems},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1),(_vm.type == 'Equipment')?_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"dense":"","outlined":"","clearable":"","label":"TcHolder","items":_vm.tcHolders.map(function (t) {
                    return {
                      text: t.name,
                      value: t.id,
                    };
                  }).sort((a, b) => a.text.localeCompare(b.text))},model:{value:(_vm.tcHolder),callback:function ($$v) {_vm.tcHolder=$$v},expression:"tcHolder"}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","offset":"1"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Ata","items":_vm.atas.map(function (t) {
                    return {
                      text: t.chapter + ' - ' + t.nameFr,
                      value: t.id,
                    };
                  })},model:{value:(_vm.ata),callback:function ($$v) {_vm.ata=$$v},expression:"ata"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{attrs:{"dense":"","outlined":"","label":"Catégorie générique","items":_vm.categories.map(function (t) {
                    return {
                      text: t.name,
                      value: t.id,
                    };
                  }).sort((a, b) => a.text.localeCompare(b.text))},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5","offset":"1"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Désignation Fr","required":"","hint":"ex: Tuyauterie souple carburant"},model:{value:(_vm.designationFr),callback:function ($$v) {_vm.designationFr=$$v},expression:"designationFr"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Désignation En","required":""},model:{value:(_vm.designationEn),callback:function ($$v) {_vm.designationEn=$$v},expression:"designationEn"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6","offset":"3"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","label":"Part Number","required":"","hint":"ex: RAP-215-CC"},model:{value:(_vm.reference),callback:function ($$v) {_vm.reference=$$v},expression:"reference"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"mt-8",attrs:{"cols":"12","align":"center"}},[_c('sbuttonCancel',{attrs:{"to":"/admin?tab=piece"}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"mr-4 primary primary-text",attrs:{"dark":""},on:{"click":_vm.updatePartModel}},[_vm._v("Créer")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }