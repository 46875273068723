<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-row></v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="itemStops"
            :items-per-page="18"
            dense
            hide-default-footer
            no-results-text="Aucun résultat"
          >
            <template v-slot:item.action="{ item }">
              <span>{{ item.action.name }} {{ item.additionalInfoFr }}</span>
            </template>
            <template v-slot:item.usedText="{ item }">
              <span v-for="v in item.usedText" :key="v['unit_code']">
                {{ v["text"] }} <br
              /></span>
            </template>
            <template v-slot:item.remainingText="{ item }">
              <span v-for="v in item.remainingText" :key="v['unit_code']">
                {{ v["text"] }} <br
              /></span>
            </template>
            <template v-slot:item.rg="{ item }">
              <span v-if="item.globalCheckLink == true">lié RG</span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style>
</style>


<script>
import { itemStopsQuery } from "../../../graphql/item_stop/item_stops";

export default {
  name: "dataTabledeviation",
  props: {
    aircraft: {
      type: Object,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      // aircraft: null,
      itemStops: [],
      isLoading: true,
      headers: [
        { text: "Butée", value: "stop.name" },
        { text: "", value: "rg" },
        { text: "Action", value: "action" },
        { text: "Pot. Effectué", value: "usedText", width: "250px" },
        { text: "Pot Reste", value: "remainingText", width: " 250px" },
      ],
    };
  },
  methods: {},
  apollo: {
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          hasDeviation: true,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>
