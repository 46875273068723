<template>
  <v-sheet outlined class="pb-8">
    <v-row>
      <v-col cols="12">
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <v-switch
                inset
                label="afficher tous les constructeurs"
                v-model="all"
              ></v-switch>
            </v-col>
            <v-col cols="2" offset="3">
              <v-select
                label="type"
                :items="typeOptions"
                v-model="typeFilter"
              ></v-select>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Recherche"
                single-line
                hide-details
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="filteredTcHolders"
          :items-per-page="-1"
          hide-default-footer
          :search="search"
          :loading="isLoading"
          loading-text="Chargement... merci de patienter"
          dense
          no-results-text="Aucun résultat"
        >
          <template v-slot:item.type="{ item }">
            {{ printType(item) }}</template
          >
          <template v-slot:item.partModels="{ item }">
            <span class="text-caption" v-html="printModel(item)"></span>
          </template>
          <template v-slot:item.lastCheckAt="{ item }">
            <v-chip outlined small :color="linkColor(item)">
            {{
              item.lastCheckAt
                | moment("calendar", null, {
                  sameDay: "[Aujourd'hui]",
                  lastDay: "[Hier]",
                  lastWeek: "dddd [dernier]",
                  sameElse: "DD MMMM",
                })
            }}</v-chip></template
          >
          <template v-slot:item.name="{ item }">
            <v-btn small target="_blank" text :to="{ name: 'tc_holder', params: { id: item.id } }">{{
              item.name
            }}</v-btn>
          </template>
          <template v-slot:item.website="{ item }">
            <v-btn
              icon
              :href="item.website"
              @click="updateLastCheck(item)"
              target="_blank"
              v-if="item.website"
            >
              <v-icon color="blue">mdi-earth</v-icon>
            </v-btn>
          </template>
          <template v-slot:item.observation="{ item }">
            <p v-if="item.observation" v-html="item.observation.replaceAll('\n','<br />')"></p>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { tcHoldersVeilleQuery } from "../../graphql/tc_holders_veille";
import { updateTcHolderMutation } from "../../graphql/update_tc_holder";
import { DateTime } from "luxon";

export default {
  name: "dataTableVeille",
  data() {
    return {
      search: "",
      typeFilter: "all_not_piece",
      all: false,
      tcHolders: [],
      typeOptions: [
        { text: "Tous (sauf pièce)", value: "all_not_piece" },
        { text: "Cellule", value: "Cell" },
        { text: "Moteur", value: "Motor" },
        { text: "Helice", value: "Propeller" },
        { text: "Pièce", value: "Part" },
      ],
      isLoading: true,
      headers: [
        {
          text: "Date",
          value: "lastCheckAt",
          width: "150px",
          groupable: false,
        },
        { text: "Type", value: "type", align: "center", width: "50px" },
        {
          text: "Lien",
          value: "website",
          align: "center",
          width: "50px",
          groupable: false,
        },
        { text: "TcHolder", value: "name", groupable: false },
        {
          text: "Modele base",
          value: "partModels",
          
          groupable: false,
        },
        { text: "Note", value: "observation" },
      ],
    };
  },
  methods: {
    updateLastCheck(item) {
      this.$apollo
        .mutate({
          mutation: updateTcHolderMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
              attributes: {
                lastCheckAt: new Date().toISOString(),
              },
            },
          },
        })
        .then((data) => {
          console.log(data);
          // this.$apollo.queries.tcHolders.refetch();
        });
    },
    printType(item) {
      var a = [];
      if (item.cell) {
        a.push("Cellule");
      }
      if (item.motor) {
        a.push("Moteur");
      }
      if (item.propeller) {
        a.push("Hélice");
      }
      if (item.part) {
        a.push("Pièce");
      }
      return a.join("/");
    },
    printModel(item) {
      var models = [];
      item.partModels.forEach((partModel) => {
        if (
          partModel.typeRaw != "Part" &&
          partModel.serie == false &&
          partModel.countAircraft > 0
        ) {
          var ref = (partModel.typeRaw == "Equipment" ? partModel.designation : "") + " " + partModel.reference + " (" + partModel.countAircraft + ")";
          models.push(ref);
        }
      });
      return models.sort().join("<br />");
    },
    linkColor(item) {
        if (item.lastCheckAt) {
          const diffDate = DateTime.now()
            .diff(DateTime.fromISO(item.lastCheckAt), "days")
            .toObject();
          if (diffDate["days"] < 6) {
            return "green";
          }
        }
        return "accent";
     
    },
  },
  computed: {
    filteredTcHolders() {
      if (this.all) {
        return this.tcHolders;
      } else {
        var holders = [];

        this.tcHolders.forEach((h) => {
          var hasAircraft = false;
          h.partModels.forEach((partModel) => {
            if (
              partModel.typeRaw != "Part" &&
              partModel.serie == false &&
              partModel.countAircraft > 0
            ) {
              hasAircraft = true;
            }
          });
          if (hasAircraft) {
            holders.push(h);
          }
        });
        return holders;
      }
    },
  },
  apollo: {
    tcHolders: {
      query: tcHoldersVeilleQuery,
      variables() {
        return {
          type: this.typeFilter,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
  },
};
</script>