<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title >
              <v-btn
              v-if="aircraft"
              outlined
              color="primary"
                :to="{
                  name: 'aeronef_part_new',
                  params: { immatriculation: aircraft.immatriculation },
                }"
                ><v-icon left dark> mdi-plus </v-icon>Nouvelle Pièce</v-btn
              >

          <v-spacer></v-spacer>
            <v-row>
            <v-col cols="3" offset="5">
              <v-switch v-model="groupTable" label="Groupé"></v-switch>
            </v-col>
              <v-col cols="4">       
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
              </v-col></v-row>
          </v-card-title>
          <v-data-table
           class="pb-6"
            dense
            :headers="headers"
            :items="aircraftParts"
          :items-per-page="-1"
            :sort-by="['partModel.ata.chapter', 'partModel.designation']"
            hide-default-footer
            :search="search"
            :loading="isLoading"
            loading-text="Chargement... merci de patienter"
            no-results-text="Aucun résultat"
            :group-by="groupField"
            disable-pagination
          >
          <!-- @click:row="handleClick" pour aller sur la fiche-->
          <!-- https://stackoverflow.com/questions/58025420/how-can-i-add-a-click-event-to-the-v-data-table -->
            <template v-slot:group.header="{items, isOpen, toggle}">
              <th class="btn" colspan="7">
                <v-icon @click="toggle"
                  >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                </v-icon>
                <span class="ml-8" style="text-transform:uppercase; font-weight:bold ">{{ items[0].attachGroup }}</span>
              </th>
            </template>
            <template v-slot:item.action="{ item }">
              <v-btn icon>
                <v-btn
                  icon
                  v-if="item.aircraft"
                  :to="{
                    name: 'aeronef_part',
                    params: {
                      immatriculation: item.aircraft.immatriculation,
                      id: item.id,
                    },
                  }"
                  ><v-icon color="primary">mdi-eye-outline</v-icon></v-btn
                >
                <v-btn
                  small
                  icon
                  v-else
                  :to="{
                    name: 'part',
                    params: { id: item.id },
                  }"
                  ><v-icon color="primary">mdi-eye-outline</v-icon></v-btn
                >
              </v-btn>
            </template>
            <template v-slot:item.partModel.designation="{ item }">
              <div v-if="item.position"> {{ item.partModel.designation }} <span  class="font-italic">({{ item.position}})</span></div>
              <span v-else> {{ item.partModel.designation }}</span>
            </template>
            <template v-slot:item.category="{ item }">
              <span v-if="item.partModel.tcHolder"> {{ item.partModel.tcHolder.name }}</span>
              <span v-else> {{ item.partModel.category.name }}</span>
            </template>
            <template v-slot:item.partModel.reference="{ item }">
              <span v-if="item.partModel.reference"> {{ item.partModel.reference }}</span>
              <span v-else> Toutes références</span>

            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.v-data-table >>> .v-data-table-header {
  background-color: #b9e0e2 !important;
}
</style>


<script>
import { aircraftPartsQuery } from "@/graphql/aircraft_part/aircraft_parts";
import { aircraftQuery } from "@/graphql/aircraft/aircraft";

export default {
  name: "dataTablePieces",
  components : { 
  },
  props: {
    aircraftId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      aircraft: null,
      aircraftParts: [],
      search: "",
      isLoading: true,
      categoryFilter: "active",
      groupTable: true,
    };
  },
  computed: {
    headers: function () {
      if (this.aircraftId) {
        return [
          { text: "", value: "action", width: "50px" },
          { text: "ATA", value: "partModel.ata.chapter", width: "70px" },
          {
            text: "CAT/TCHOLDER",
            value: "category",
            width: "200px",
          },
          { text: "DESIGNATION", value: "partModel.designation" },
          { text: "PART/MODEL", value: "partModel.reference" },
          { text: "S/N", value: "serialNumber", width: "150px" },
          
        ];
      } else {
        return [
          { text: "IMMAT", value: "aircraft.immatriculation", width: "100px" },
          { text: "ATA", value: "partModel.ata.chapter", width: "70px" },
          {
            text: "CATEGORIE",
            value: "partModel.category.name",
            width: "200px",
          },
          {
            text: "TCHOLDER",
            value: "partModel.tcHolder.name",
            sortable: true,
          },
          { text: "DESIGNATION", value: "partModel.designation" },
          { text: "PART/MODEL", value: "partModel.reference" },
          { text: "S/N", value: "serialNumber", width: "100px" },
          { text: "", value: "action", width: "120px" },
        ];
      }
    },
    groupField: function() {
      return this.groupTable ? 'attachGroup' : null
    }
  },
  apollo: {
    aircraftParts: {
      query: aircraftPartsQuery,
      variables() {
        return {
          aircraftId: this.aircraftId ? parseInt(this.aircraftId, 10) : null,
          type: null,
        };
      },
      result() {
        this.isLoading = false;
      },
    },
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
  },
};
</script>
