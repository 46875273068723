import gql from 'graphql-tag';

export const aircraftsQuery = gql`query Aircrafts($status: String, $supervisor: ID, $contract: String)
      {
        aircrafts(status: $status, supervisor: $supervisor, contract: $contract) {
          id
          status
          immatriculation
          easaType
          easaPartType
          mtow
          contact {
              id
              name
          }
          supervisor {
            id
            name
          }
          contract {
            id
            register
            contractType
          }
          cell {
            id
            serialNumber
            manufacturingDate
            flyValue
            manufacturer {
              id
              name
            }
            partModel {
              id
              reference
              fullName
              mtow
              tcds
              tcHolder {
                id
                name
              }                
            }
          }
          motors {
            id
            serialNumber
            partModel {
              id
              reference
              fullName
              tcHolder {
                id
                name
              }              
            }
          }
          propellers {
            id
            serialNumber
            partModel {
              id
              reference
              fullName
              tcHolder {
                id
                name
              }
            }
          }
        }
      }`
