import gql from 'graphql-tag';

export const aircraftPartFragment = {
    fragments: {
      aircraftPart: gql`
      fragment aircraftPart on AircraftPart {
        id
        serialNumber
        manufacturingDate
        installDate
        flyValue
        independantHour
        notes
        manualsData
        status
        noTotalHours
        manuals {
          id
          number
          title
        }
        position
        attachGroup
        formOne {
          id
          name
          fileUrl
          fileSignedId
        }
        aircraft {
          id
          immatriculation
        }
        manufacturer {
          id
          name
        }
        partModel {
          id
          reference
          designation
          type
          typeRaw
          tcHolder {
            id
            name
          }
          ata {
            id
            chapter
          }
          category {
            id
            name
          }
        }
        attach {
          id
          serialNumber
          partModel {
            id
            reference
            type
            tcHolder {
              id
              name
            }
            ata {
              id
              chapter
            }
            category {
              id
              name
            }
          }
        }
        flyDataChanges {
          id
          dateChange
          dataChanges
          changeType
          itemStop {
            id
            stop {
              id
              name
            }
          }
        }
        aircraftPartDocuments {
          id
          name
          documentAttachment {
            id
            name
            fileUrl
            fileSignedId
            createdAt
          }
        }
      }` 
    }
}