<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-dialog v-model="dialog_upload" max-width="600">
          <template v-slot:activator="{ on }">
            <v-row>
              <v-col cols="1" align="center">
                <v-btn icon v-on="on" @click="dialog_upload = !dialog_upload">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="11"
                ><div class="mt-2">
                  {{ title }} <br />
                 <span class="text-caption font-italic">{{ subtitle }}</span>
                </div>
              </v-col>
            </v-row>
          </template>
          <v-card>
            <v-card-title>Ajouter des élements à :</v-card-title>
            <v-card-subtitle>{{ title }}</v-card-subtitle>
            <v-card-text class="pt-4">
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <p><strong>Description de la maintenance:</strong></p>
                    <tiptap-vuetify
                      v-model="description"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                    />
                  </v-col>
                  <v-col cols="12">
                    <p><strong>Intervalles :</strong></p>
                    <tiptap-vuetify
                      v-model="interval"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                    />
                  </v-col>
                  <v-col cols="12">
                    <p><strong>Réferences:</strong></p>
                    <tiptap-vuetify
                      v-model="reference"
                      :extensions="extensions"
                      :card-props="{ outlined: true }"
                      :toolbar-attributes="{
                        dense: true,
                        color: '#eceff1',
                      }"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-radio-group dense v-model="deviation" row>
                      <div class="mr-4">Deviation ?</div>
                      <v-radio color="#1871ce" label="Oui" :value="true">
                      </v-radio>
                      <v-radio color="#1871ce" label="Non" :value="false">
                      </v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col v-if="itemId" cols="12" align="center">
                    <v-btn small color="primary" @click="updateData()">
                      Mettre à jour
                    </v-btn>
                  </v-col>
                  <v-col v-else cols="12" align="center">
                    <v-btn small color="primary" @click="addData()">
                      Ajouter
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>

    <v-col cols="12" v-if="maintenanceProgrammeItems.length > 0">
      <v-card outlined color="success">
        <v-simple-table style="border-radius: 4px">
          <thead>
            <tr>
              <th>Description</th>
              <th>Intervalles</th>
              <th>Référence</th>
              <th>Déviation</th>
              <th style="width: 110px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="d in maintenanceProgrammeItems" :key="d.id">
              <td v-html="d.description"></td>
              <td v-html="d.interval"></td>
              <td v-html="d.reference"></td>
              <td>{{ d.deviation ? "Oui" : "Non " }}</td>
              <td>
                <v-btn icon class="mb-4" @click="editData(d.id)">
                  <v-icon color="primary">mdi-pencil</v-icon>
                </v-btn>

                <v-btn class="mb-4" icon @click="removeData(d)">
                  <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card></v-col
    >
  </v-row>
</template>

<script>
import {
  TiptapVuetify,
  Bold,
  Italic,
  Underline,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak,
} from "tiptap-vuetify";
import { maintenanceProgrammeItemsQuery } from "../../graphql/maintenance_programme/maintenance_programme_items";
import { createMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/create_maintenance_programme_item";
import { updateMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/update_maintenance_programme_item";
import { deleteMaintenanceProgrammeItemMutation } from "../../graphql/maintenance_programme/delete_maintenance_programme_item";

export default {
  name: "AnnexeBForm",
  props: ["programmeId", "type", "title", "subtitle"],
  components: {
    TiptapVuetify,
  },
  data() {
    return {
      maintenanceProgrammeItems: [],
      description: null,
      reference: null,
      interval: null,
      itemId: null,
      deviation: false,
      extensions: [
        Bold,
        Italic,
        Underline,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak,
      ],
      dialog_upload: false,
      modal: false,
    };
  },

  methods: {
    addData() {
      if (this.description) {
        this.$apollo
          .mutate({
            mutation: createMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                attributes: {
                  itemType: this.type,
                  maintenanceProgrammeId: this.programmeId,
                  interval: this.interval,
                  description: this.description,
                  reference: this.reference,
                  deviation: this.deviation,
                },
              },
            },
          })
          .then(() => {
            this.description = null;
            this.reference = null;
            this.interval = null;
            this.deviation = false;
            this.dialog_upload = false;
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      }
    },
    editData(id) {
      const item = this.maintenanceProgrammeItems.find((mpi) => mpi.id == id);
      this.itemId = item.id;
      this.description = item.description;
      this.reference = item.reference;
      this.interval = item.interval;
      this.deviation = item.deviation;
      this.dialog_upload = true;
    },
    updateData() {
      if (this.description) {
        this.$apollo
          .mutate({
            mutation: updateMaintenanceProgrammeItemMutation,
            variables: {
              input: {
                id: parseInt(this.itemId, 10),
                attributes: {
                  itemType: this.type,
                  maintenanceProgrammeId: this.programmeId,
                  interval: this.interval,
                  description: this.description,
                  reference: this.reference,
                  deviation: this.deviation,
                },
              },
            },
          })
          .then(() => {
            this.description = null;
            this.reference = null;
            this.interval = null;
            this.itemId = null;
            this.deviation = false;
            this.dialog_upload = false;
            this.$apollo.queries.maintenanceProgrammeItems.refetch();
          });
      }
    },
    removeData(item) {
      this.$apollo
        .mutate({
          mutation: deleteMaintenanceProgrammeItemMutation,
          variables: {
            input: {
              id: parseInt(item.id, 10),
            },
          },
        })
        .then(() => {
          this.$apollo.queries.maintenanceProgrammeItems.refetch();
        });
    },
  },
  apollo: {
    maintenanceProgrammeItems: {
      query: maintenanceProgrammeItemsQuery,
      variables() {
        return {
          itemType: this.type,
          maintenanceProgrammeId: parseInt(this.programmeId, 10),
        };
      },
      skip() {
        return !this.programmeId;
      },
    },
  },
};
</script>
