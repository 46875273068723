import gql from 'graphql-tag';

export const templateItemStopQuery = gql`
query templateItemStop($id: ID!) {
  templateItemStop(id: $id) {
    action {
      id
      name  
    }
    globalCheckLink
    additionalInfoFr
    stop {
      id
      name
      inspection
    }
  }
}`