<template>
  <v-container v-if="$apollo.loading">
    <v-row>
      <v-progress-linear color="blue" indeterminate></v-progress-linear>
    </v-row>
  </v-container>
  <v-container v-else fluid>
    <v-row>
      <v-col flex>
        <v-tabs
          v-model="tab"
          background-color="#eceff1"
          color="#23afb9"
          centered
          center-active
          grow
          show-arrows
          icons-and-text
        >
          <v-tabs-slider color="#23afb9"></v-tabs-slider>
          <v-tab href="#flotte">
            Liste de flotte
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-tab>
          <v-tab href="#last-immat">
              Entrées / Sorties
            <v-icon>mdi-airplane</v-icon>
          </v-tab>
          <v-tab href="#avion-sans-contrat">
              Avion Sans Contrat
            <v-icon>mdi-clipboard-list-outline</v-icon>
          </v-tab>
          <v-tab href="#gestionnaire" v-if="$permissions.can(me, 'admin')" >
              Gestionnaire
            <v-icon>mdi-account-group</v-icon>
          </v-tab>
          <v-tab href="#workorder">
              Ordre de travail
            <v-icon>mdi-airplane</v-icon>
          </v-tab>
          <v-tab href="#plane" v-if="$permissions.can(me, 'admin')" >
              Aéronef
            <v-icon>mdi-airplane</v-icon>
          </v-tab>
          <v-tab href="#piece" v-if="$permissions.can(me, 'admin')" >
              Pièce
            <v-icon>mdi-tools</v-icon>
          </v-tab>
          <v-tab href="#ad/sb" v-if="$permissions.can(me, 'admin')" >
              AD/SB
            <v-icon>mdi-book-open-variant</v-icon>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="flotte">
            <flotte />
          </v-tab-item>
          <v-tab-item value="last-immat">
            <last-immat />
          </v-tab-item>
          <v-tab-item value="avion-sans-contrat">
            <avion-sans-contrat />
          </v-tab-item>
          <v-tab-item value="gestionnaire">
            <gestionnaire />
          </v-tab-item>
          <v-tab-item value="workorder">
            <workorder />
          </v-tab-item>
          <v-tab-item value="plane">
            <plane />
          </v-tab-item>
          <v-tab-item value="piece">
            <gestion-piece />
          </v-tab-item>
          <v-tab-item value="ad/sb">
            <gestion-ad />
          </v-tab-item>
          <v-tab-item>

          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<script>
import flotte from './Flotte.vue';
import AvionSansContrat from "./AvionSansContrat";
import gestionnaire from "./Gestionnaire";
import LastImmat from './LastImmat.vue';
import workorder from './Workorder.vue';
import plane from './Plane.vue';
import { meQuery } from "@/graphql/me";
import GestionPiece from './GestionPiece.vue';
import GestionAd from './GestionAd.vue';

export default {
  name: "Index",
  data: function () {
    return {};
  },
  components: {
    flotte,
    AvionSansContrat,
    gestionnaire,
    LastImmat,
    workorder,
    plane,
    GestionPiece,
    GestionAd
    },
  computed: {
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab;
      },
    },
  },
  apollo: {
    me: {
      query: meQuery,
    },
  },
};
</script>