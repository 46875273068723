import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';

export const finishInitialisationMutation = gql`
  mutation finishInitialisation($input: FinishInitialisationInput!) {
    finishInitialisation(input: $input) {
      aircraft {
        ...aircraft
      }
    }
  }
  ${aircraftFragment.fragments.aircraft}
  `
