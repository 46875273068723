<template>
  <v-container fluid>
    <v-col cols="6" offset="3">
      <v-sheet outlined>
        <v-form>
          <v-card-title class="headline text-uppercase">
            <span class="titre--text">Nouvel aeronef</span>
          </v-card-title>
          <v-divider style="padding-bottom: 50px"></v-divider>
          <v-row>
            <v-col cols="6" offset="3">
              <v-text-field
                outlined
                dense
                v-model="immatriculation"
                label="Immatriculation"
                required
                :rules="[rules.uniq]"
              ></v-text-field>
            </v-col>
          </v-row>          
          <v-row>
            <v-col cols="6" offset="3">
              <v-autocomplete
                v-model="partModel"
                :items="
                  partModels.map(function (t) {
                    return {
                      text: t.tcHolder.name + ' - ' + t.reference,
                      value: t.id,
                    };
                  }).sort((a, b) => a.text.localeCompare(b.text))
                "
                label="Modèle"
                required
                outlined
                dense
                v-if="partModels.length > 0"
              ></v-autocomplete>
            </v-col>
          </v-row>          
          <v-row>
            <v-col cols="6" offset="3" class="mb-4">
              <v-radio-group v-model="status" row>
                <v-radio value="new" label="En gestion"></v-radio>
                <v-radio value="classif" label="Classification"></v-radio
              ></v-radio-group>
            </v-col>
          </v-row>
          <v-row style="padding-bottom: 50px">
            <v-col cols="6" align="center" offset="3">
              <sbuttonCancel to="/liste_immat">Annuler</sbuttonCancel>
              <v-btn
                class="mr-4 primary primary-text"
                dark
                @click="createAircraft()"
                >Créer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<style scoped>
.v-input__flow {
  min-height: 38px !important;
}
</style>

<script>
import { partModelsQuery } from "../../graphql/part_model/part_models";
import { contactsQuery } from "../../graphql/contact/contacts";
import { createAircraftMutation } from "@/graphql/aircraft/create_aircraft";
import { aircraftsimmatQuery } from "../../graphql/aircraft/aircrafts_immat";
//import sbuttonSave from "../../components/v-btn.vue"
import sbuttonCancel from "../../components/sbuttonCancel.vue";

export default {
  name: "AeronefNew",
  components: {
    //sbuttonSave,
    sbuttonCancel,
  },
  data() {
    return {
      immatriculation: "",
      partModel: "",
      partModels: [],
      status: "new",      
      aircrafts: [],
      rules: {
        uniq: (value) =>
          !this.aircrafts.find((a) => a.immatriculation == value) ||
          "Immatriculation existante",
      },
      templates: null,
    };
  },
  apollo: {
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Cell"],
      },
    },
    contacts: {
      query: contactsQuery,
    },
    aircrafts: {
      query: aircraftsimmatQuery,
    },
  },  
  methods: {
    createAircraft() {
      this.$apollo
        .mutate({
          mutation: createAircraftMutation,
          variables: {
            input: {
              attributes: {
                immatriculation: this.immatriculation,
                partModelId: this.partModel,
                status: this.status,
                template: this.template
              },
            },
          },
        })
        .then(() => {
          this.$router.push({
            name: "aeronef",
            params: { immatriculation: this.immatriculation },
          });
        });
    },
  },
};
</script>