import gql from 'graphql-tag';

export const generateExportAircraftsMutation = gql`
  mutation generateExportAircrafts($input: GenerateExportAircraftsInput!) {
    generateExportAircrafts(input: $input)  {
      customQuery {
        id
        name
        report
        edition
        customQueryType
        updatedAt
        createdAt
      }
    }
  }
  `
