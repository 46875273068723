import gql from 'graphql-tag';
import { aircraftFragment } from './fragment_aircraft';

export const initialisationAircraftMutation = gql`
  mutation initialisationAircraft($input: InitialisationAircraftInput!) {
    initialisationAircraft(input: $input) {
      aircraft {
        ...aircraft
      }
    }
  }
  ${aircraftFragment.fragments.aircraft}
  `
