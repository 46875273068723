var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-title',[(_vm.aircraft)?_c('v-btn',{attrs:{"outlined":"","color":"primary","to":{
                name: 'aeronef_part_new',
                params: { immatriculation: _vm.aircraft.immatriculation },
              }}},[_c('v-icon',{attrs:{"left":"","dark":""}},[_vm._v(" mdi-plus ")]),_vm._v("Nouvelle Pièce")],1):_vm._e(),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"3","offset":"5"}},[_c('v-switch',{attrs:{"label":"Groupé"},model:{value:(_vm.groupTable),callback:function ($$v) {_vm.groupTable=$$v},expression:"groupTable"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{staticClass:"pb-6",attrs:{"dense":"","headers":_vm.headers,"items":_vm.aircraftParts,"items-per-page":-1,"sort-by":['partModel.ata.chapter', 'partModel.designation'],"hide-default-footer":"","search":_vm.search,"loading":_vm.isLoading,"loading-text":"Chargement... merci de patienter","no-results-text":"Aucun résultat","group-by":_vm.groupField,"disable-pagination":""},scopedSlots:_vm._u([{key:"group.header",fn:function({items, isOpen, toggle}){return [_c('th',{staticClass:"btn",attrs:{"colspan":"7"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(_vm._s(isOpen ? 'mdi-minus' : 'mdi-plus')+" ")]),_c('span',{staticClass:"ml-8",staticStyle:{"text-transform":"uppercase","font-weight":"bold"}},[_vm._v(_vm._s(items[0].attachGroup))])],1)]}},{key:"item.action",fn:function({ item }){return [_c('v-btn',{attrs:{"icon":""}},[(item.aircraft)?_c('v-btn',{attrs:{"icon":"","to":{
                  name: 'aeronef_part',
                  params: {
                    immatriculation: item.aircraft.immatriculation,
                    id: item.id,
                  },
                }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1):_c('v-btn',{attrs:{"small":"","icon":"","to":{
                  name: 'part',
                  params: { id: item.id },
                }}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-eye-outline")])],1)],1)]}},{key:"item.partModel.designation",fn:function({ item }){return [(item.position)?_c('div',[_vm._v(" "+_vm._s(item.partModel.designation)+" "),_c('span',{staticClass:"font-italic"},[_vm._v("("+_vm._s(item.position)+")")])]):_c('span',[_vm._v(" "+_vm._s(item.partModel.designation))])]}},{key:"item.category",fn:function({ item }){return [(item.partModel.tcHolder)?_c('span',[_vm._v(" "+_vm._s(item.partModel.tcHolder.name))]):_c('span',[_vm._v(" "+_vm._s(item.partModel.category.name))])]}},{key:"item.partModel.reference",fn:function({ item }){return [(item.partModel.reference)?_c('span',[_vm._v(" "+_vm._s(item.partModel.reference))]):_c('span',[_vm._v(" Toutes références")])]}}])})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }