var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.aircraft)?_c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',{staticStyle:{"padding-bottom":"30px"},attrs:{"outlined":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"headline text-uppercase"},[_c('p',{staticClass:"primary--text"},[_vm._v(" Modifier | "+_vm._s(_vm.aircraft.immatriculation)+" "),_c('span',{staticClass:"text-caption",staticStyle:{"margin-bottom":"0"}},[_vm._v("Dernière mise à jour : "+_vm._s(_vm._f("moment")(_vm.aircraft.updatedAt,"DD/MM/YYYY")))])])]),_c('v-divider',{staticStyle:{"padding-bottom":"30px"}}),_c('v-form',[_c('v-row',[_c('v-col',{staticClass:"pt-4 pl-6",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","offset":"1"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","hint":"Non modifiable","persistent-hint":"","placeholder":"Immatriculation"},model:{value:(_vm.immatriculation),callback:function ($$v) {_vm.immatriculation=$$v},expression:"immatriculation"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","offset":"1"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","hint":"Non modifiable","persistent-hint":"","placeholder":"Client"},model:{value:(_vm.contact),callback:function ($$v) {_vm.contact=$$v},expression:"contact"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","offset":"1"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","disabled":"","hint":"Non modifiable","persistent-hint":"","placeholder":"Date d'initialisation des butées","value":_vm.formatDate(_vm.start)}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","offset":"1"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"gestionnaire principal","items":_vm.users.map(function (u) {
                      return { text: u.name, value: u.id };
                    }).sort((a, b) => a.text.localeCompare(b.text))},model:{value:(_vm.pen),callback:function ($$v) {_vm.pen=$$v},expression:"pen"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10","offset":"1"}},[_c('v-autocomplete',{attrs:{"label":"Atelier par défaut","outlined":"","dense":"","items":_vm.workshops.map(function (a) {
                      return { text: a.name, value: a.id };
                    }).sort((a, b) => a.text.localeCompare(b.text))},model:{value:(_vm.workshop),callback:function ($$v) {_vm.workshop=$$v},expression:"workshop"}})],1)],1)],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Carburant","items":_vm.fuelOptions,"multiple":""},model:{value:(_vm.fuelFilter),callback:function ($$v) {_vm.fuelFilter=$$v},expression:"fuelFilter"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","label":"MTOW","suffix":"Kg"},model:{value:(_vm.modifiedMtow),callback:function ($$v) {_vm.modifiedMtow=$$v},expression:"modifiedMtow"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"5"}},[_c('v-checkbox',{attrs:{"label":"Forcer la mtow","false-value":false,"true-value":true},model:{value:(_vm.mtowForced),callback:function ($$v) {_vm.mtowForced=$$v},expression:"mtowForced"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Où sera Stationner l'aéronef","items":_vm.parkingTypeList},model:{value:(_vm.parkingType),callback:function ($$v) {_vm.parkingType=$$v},expression:"parkingType"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-switch',{attrs:{"dense":"","label":"L'aéronef est-il en maintenance longue ?","false-value":false,"true-value":true},model:{value:(_vm.longMaintenance),callback:function ($$v) {_vm.longMaintenance=$$v},expression:"longMaintenance"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"10"}},[_c('v-switch',{attrs:{"dense":"","label":"L'aéronef est-il en annexe 1 ?","false-value":false,"true-value":true},model:{value:(_vm.annexe1),callback:function ($$v) {_vm.annexe1=$$v},expression:"annexe1"}})],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-8",attrs:{"cols":"12","align":"center"}},[_c('sbuttonCancel',{attrs:{"exact":true,"to":{
                name: 'aeronef',
                params: { immatriculation: _vm.aircraft.immatriculation, page: 'info' },
              }}},[_vm._v("Annuler")]),_c('v-btn',{staticClass:"mr-4 primary primary-text",attrs:{"dark":""},on:{"click":function($event){return _vm.updateAircraft()}}},[_vm._v("Enregistrer")])],1)],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }