<template>
  <v-container fluid>
    <v-sheet>
      <v-card-title>
        <v-col cols="4">
          <v-select dense v-model="typeDoc" :items="adFilterList"> </v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          >
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-data-table
        :headers="headers"
        fixed-header
        :sort-by="['document.tcHolders', 'document.numberRev']"
        :sort-desc="[true, false]"
        :items-per-page="15"
        :footer-props="{
          'items-per-page-options': [15, 50, 100, -1],
          'items-per-page-text': 'lignes par page',
          'items-per-page-all-text': 'Tous',
        }"
        dense
        :search="search"
        :loading="isLoading"
        :items="documentProcessings"
        no-results-text="Aucun résultat trouvé"
        no-data-text="Aucun(s) document(s) en attente de traitement"
        loading-text="Chargement... merci de patienter"
      >
        <template v-slot:item.document.tcHolders="{ item }">
          <span
            v-for="tcHolder in item.document.tcHolders"
            :key="tcHolder.id"
            class="d-inline-block text-truncate"
            style="max-width: 150px"
            >{{ tcHolder.name }} <br
          /></span>
        </template>
        <template v-slot:item.document.numberRev="{ item }">
          <v-btn
            text
            target="_blank"
            :to="{ name: 'document', params: { id: item.document.id } }"
            >{{ item.document.numberRev }}</v-btn
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <traitement-component
            :processingId="parseInt(item.id, 10)"
          ></traitement-component>
        </template>
        <template v-slot:item.partModels="{ item }">
          <template v-if="item.document.targetsModels.length > 0">
            <v-card-text>
              <v-chip
                outlined
                disable
                v-for="model in item.document.targetsModels"
                :key="model.id"
                >{{ model.reference }}
              </v-chip>
            </v-card-text>
          </template>
          <template v-if="item.document.targets['all'] == true"
            >Tous les Modèles</template
          >
          <template
            v-if="
              item.document.targets['all_model_for_tc_holder'] == true &&
              document.targets['all'] == false
            "
          >
            Tous Modèles du TCHolder
            <span v-for="tcHolder in item.document.tcHolders" :key="tcHolder.id"
              >&nbsp;{{ tcHolder.name }}</span
            >
          </template>
        </template>
        <template v-slot:item.detail="{ item }">
          <span
            v-if="item.process == 'apply'"
            v-html="printApplied(item)"
          ></span>
          <span v-if="item.process == 'stop'">
            <span v-for="itemStop in item.itemStops" :key="itemStop.id">
              {{ itemStop.stop.name }}<br />
            </span>
          </span>
          <span v-else> {{ item.detailsFr }}</span>
          <span v-if="item.document.replaceBy"
            ><br />annulé et remplacé par
            {{ item.document.replaceBy.numberRev }}</span
          >
        </template>
        <template v-slot:item.document.title="{ item }">
          <span
            v-if="
              item.document.status == 'replace' ||
              item.document.status == 'cancel'
            "
            >[ANNULE] {{ item.document.title }}</span
          >
          <span v-else> {{ item.document.title }}</span>
        </template>
      </v-data-table>
    </v-sheet>
  </v-container>
</template>

<style scoped>
.v-alert {
  margin-bottom: 0;
}
.v-data-table >>> .v-data-table-header {
  background-color: #b9e0e2 !important;
}
</style>

<script>
import { documentProcessingsQuery } from "../../graphql/document_processing/document_processings";
import { DateTime } from "luxon";
import TraitementComponent from "../Traitement/TraitementComponent.vue";

export default {
  name: "gestionAd",
  props: {
  },
  components: { TraitementComponent },
  data() {
    return {
      search: "",
      isLoading: true,
      saveLoading: false,
      documentProcessings: [],
      typeDoc: "",
      adFilterList: [
        { text: "AD", value: "Ad" },
        { text: "SB", value: "Sb" },        
      ],
      headers: [
      { text: "", value: "actions", sortable: false, width: "50px" },
      { text: "IMMAT", value: "aircraft.immatriculation", width: "100px" },
      { text: "TC HOLDER", value: "document.tcHolders" },
      { text: "NUMERO", value: "document.numberRev" },
      { text: "TITRE", value: "document.title" },
      { text: "DETAIL", value: "detail", width: "350px" },
      ]
    };
  },

  computed: {
    },

  methods: {
    printApplied(item) {
      var buff = "";
      if (item.processDate) {
        buff +=
          "Appliqué le " + DateTime.fromISO(item.processDate).toLocaleString();
      } else {
        buff += "Appliqué";
      }
      if (item.processNumber) {
        buff += " (" + item.processNumber + ")";
      }
      return buff;
    },
    },

  apollo: {
    documentProcessings: {
      query: documentProcessingsQuery,
      variables() {
        return {
          aircraftId: null,
          type: this.typeDoc,
          status: this.processFilter,
        };
      },
      skip() {
        return this.typeDoc == "";
      },
      result() {
        this.isLoading = false;
      },
    },
    
  },
};
</script>
