<template>
  <v-container fluid class="background">
    <v-col cols="8" offset="2">
      <v-sheet outlined style="padding-bottom: 30px">
        <v-form>
          <v-card-title class="headline text-uppercase">
            <div>
              <div class="primary--text">
                Créer un nouveau template pour
                <span style="font-weight: bold; color: red"> </span>
              </div>
            </div>
          </v-card-title>
          <v-divider style="padding-bottom: 50px"></v-divider>
          <v-row>
            <v-col cols="6" offset="3">
              <v-text-field
                v-model="name"
                outlined
                placeholder="Nom du template"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" offset="3">
              <v-autocomplete
                v-model="partModel"
                label="Modèle de cellule"
                required
                outlined
                dense
                :items="
                  partModels
                    .map(function (t) {
                      return { text: t.fullName, value: t.id };
                    })
                    .sort((a, b) => a.text.localeCompare(b.text))
                "
                v-if="partModels.length > 0"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" align="center" class="pt-8">
              <v-btn
                class="mr-4 btn--text"
                outlined
                depressed
                to="/admin?tab=template"
                >Annuler</v-btn
              >
              <v-btn class="mr-4 primary primary-text" dark @click="create()"
                >Continuer</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-sheet>
    </v-col>
  </v-container>
</template>

<script>
import { createTemplateAircraftMutation } from "../../../graphql/template/create_template_aircraft";
import { partModelsQuery } from "../../../graphql/part_model/part_models";

export default {
  name: "NewTemplate",
  components: {},
  data() {
    return {
      partModel: "",
      partModels: [],
      name: "",
      template: null,
    };
  },
  apollo: {
    // template: {
    //   query: templateQuery,
    // },
    partModels: {
      query: partModelsQuery,
      variables: {
        serie: false,
        type: ["Cell"],
      },
    },
  },
  methods: {
    create() {
      if (this.template) {
        // this.$apollo
        // 	.mutate({
        // 		mutation: updateTemplatePartMutation,
        // 		variables: {
        // 			input: {
        // 				id: parseInt(this.aircraftPart.id, 10),
        // 				attributes: {
        // 					partModelId: this.partModelId,
        // 				},
        // 			},
        // 		},
        // 	})
        // 	.then(() => {
        // 		this.$router.push({
        // 			name: "aeronef",
        // 			params: {
        // 				immatriculation: this.aircraft.immatriculation,
        // 				page: "pieces",
        // 			},
        // 		});
        // 	});
      } else {
        this.$apollo
          .mutate({
            mutation: createTemplateAircraftMutation,
            variables: {
              input: {
                attributes: {
                  cellId: this.partModel,
                  name: this.name,
                },
              },
            },
          })
          .then((data) => {
            if (data) {
              this.$router.push({
                name: "template",
                params: {
                  id: data.data.createTemplateAircraft.id,
                },
              });
            }
          });
      }
    },
  },
};
</script>