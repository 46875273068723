var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-sheet',[_c('v-card-title',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{attrs:{"dense":"","items":_vm.adFilterList},model:{value:(_vm.typeDoc),callback:function ($$v) {_vm.typeDoc=$$v},expression:"typeDoc"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('v-data-table',{attrs:{"headers":_vm.headers,"fixed-header":"","sort-by":['document.tcHolders', 'document.numberRev'],"sort-desc":[true, false],"items-per-page":15,"footer-props":{
        'items-per-page-options': [15, 50, 100, -1],
        'items-per-page-text': 'lignes par page',
        'items-per-page-all-text': 'Tous',
      },"dense":"","search":_vm.search,"loading":_vm.isLoading,"items":_vm.documentProcessings,"no-results-text":"Aucun résultat trouvé","no-data-text":"Aucun(s) document(s) en attente de traitement","loading-text":"Chargement... merci de patienter"},scopedSlots:_vm._u([{key:"item.document.tcHolders",fn:function({ item }){return _vm._l((item.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id,staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"150px"}},[_vm._v(_vm._s(tcHolder.name)+" "),_c('br')])})}},{key:"item.document.numberRev",fn:function({ item }){return [_c('v-btn',{attrs:{"text":"","target":"_blank","to":{ name: 'document', params: { id: item.document.id } }}},[_vm._v(_vm._s(item.document.numberRev))])]}},{key:"item.actions",fn:function({ item }){return [_c('traitement-component',{attrs:{"processingId":parseInt(item.id, 10)}})]}},{key:"item.partModels",fn:function({ item }){return [(item.document.targetsModels.length > 0)?[_c('v-card-text',_vm._l((item.document.targetsModels),function(model){return _c('v-chip',{key:model.id,attrs:{"outlined":"","disable":""}},[_vm._v(_vm._s(model.reference)+" ")])}),1)]:_vm._e(),(item.document.targets['all'] == true)?[_vm._v("Tous les Modèles")]:_vm._e(),(
            item.document.targets['all_model_for_tc_holder'] == true &&
            _vm.document.targets['all'] == false
          )?[_vm._v(" Tous Modèles du TCHolder "),_vm._l((item.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id},[_vm._v(" "+_vm._s(tcHolder.name))])})]:_vm._e()]}},{key:"item.detail",fn:function({ item }){return [(item.process == 'apply')?_c('span',{domProps:{"innerHTML":_vm._s(_vm.printApplied(item))}}):_vm._e(),(item.process == 'stop')?_c('span',_vm._l((item.itemStops),function(itemStop){return _c('span',{key:itemStop.id},[_vm._v(" "+_vm._s(itemStop.stop.name)),_c('br')])}),0):_c('span',[_vm._v(" "+_vm._s(item.detailsFr))]),(item.document.replaceBy)?_c('span',[_c('br'),_vm._v("annulé et remplacé par "+_vm._s(item.document.replaceBy.numberRev))]):_vm._e()]}},{key:"item.document.title",fn:function({ item }){return [(
            item.document.status == 'replace' ||
            item.document.status == 'cancel'
          )?_c('span',[_vm._v("[ANNULE] "+_vm._s(item.document.title))]):_c('span',[_vm._v(" "+_vm._s(item.document.title))])]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }