q<template>
  <v-container fluid v-if="aircraftPart">
    <v-sheet outlined>
      <v-card-title class="text-h5" text-align:justify>
        <div class="mr-4">
          <v-btn
            icon
            fab
            outlined
            small
            class="primary--text"
            link
            v-if="aircraftPart.aircraft"
            :to="{
              name: 'aeronef',
              params: {
                immatriculation: aircraftPart.aircraft.immatriculation,
                page: 'pieces',
              },
            }"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>

          <v-btn
            icon
            fab
            outlined
            x-small
            class="primary--text"
            link
            v-else
            :to="{ name: 'GestionPiece' }"
          >
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </div>
        <span style="margin-right: 10px" v-if="aircraftPart.partModel.ata"
          >{{ aircraftPart.partModel.ata.chapter }} -
        </span>
        <span v-if="aircraftPart.partModel.tcHolder">
          {{ aircraftPart.partModel.tcHolder.name }} -
          {{ aircraftPart.partModel.designation }}
          {{ aircraftPart.partModel.reference }}
        </span>
        <span v-else>
          {{ aircraftPart.partModel.designation }} -
          {{ aircraftPart.partModel.reference }}
        </span>
        <v-spacer></v-spacer>
        <v-menu open-on-hover offset-y bottom>
          <template v-slot:activator="{ on }">
            <v-btn small fab icon outlined class="primary--text" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group>
              <v-list-item
                :to="{
                  name: 'aeronef_part_edit',
                  params: {
                    immatriculation: aircraftPart.aircraft.immatriculation,
                    id: aircraftPart.id,
                  },
                }"
                >Modifier
              </v-list-item>
              <v-list-item @click="removePart()">
                <v-list-item-title>Supprimer</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-col cols="12">
        <v-row>
          <v-col cols="3">
            <v-row align="center">
              <template>
                <v-col cols="2">
                  <v-icon color="primary">mdi-numeric</v-icon>
                </v-col>
                <v-col cols="10" v-if="aircraftPart.serialNumber"
                  >S/N :
                  <span class="font-weight-bold font-italic primary--text">{{
                    aircraftPart.serialNumber
                  }}</span></v-col
                >
                <v-col cols="10" v-else>Pas de numéro de série</v-col>
              </template>
            </v-row>
            <v-row align="center">
              <template>
                <v-col cols="2">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-calendar-start</v-icon>
                    </template>
                    <span>Date de fabrication</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="10" v-if="aircraftPart.manufacturingDate"
                  >fabriqué le
                  <span class="font-weight-bold font-italic primary--text">
                    {{
                      aircraftPart.manufacturingDate | moment("DD/MM/YYYY")
                    }}</span
                  >
                </v-col>
                <v-col cols="10" v-else> Pas de date de fabrication</v-col>
              </template>
            </v-row>
            <v-row align="center">
              <template>
                <v-col cols="2">
                   <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-airplane-plus</v-icon>
                </v-col>
                <v-col cols="10" v-if="aircraftPart.attach"
                  >Rattaché à
                  <span class="font-weight-bold font-italic primary--text">{{
                    aircraftPart.attach.partModel.category.name
                  }}</span>
                </v-col>
                <v-col cols="10" v-else>Rattaché à aucun élement </v-col>
              </template>
            </v-row>  
          </v-col>
           <v-col cols="3">
            <!-- <v-row align="center">
          <v-col cols="1" offset="1">
            <v-icon>mdi-airplane-clock</v-icon>
          </v-col>
          <v-col cols="10" v-if="initHours"><span class="font-weight-bold font-italic primary--text">{{ initHours | formatMinutes }} </span>depuis fabrication</v-col>
          <v-col cols="10" v-else>Heures depuis fabrication inconnues</v-col>
        </v-row>
        <v-row align="center">
          <template>
            <v-col cols="1" offset="1">
              <v-icon>mdi-airplane-landing</v-icon>
            </v-col>
            <v-col cols="10" v-if="initCycles"><span class="font-weight-bold font-italic primary--text">{{ initCycles }} cycles </span>depuis fabrication</v-col>
          <v-col cols="10" v-else>Cycles depuis fabrication inconnues</v-col>
          </template>
        </v-row> -->
            <v-row align="center">
              <v-col cols="2" >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-airplane-clock</v-icon>
                    </template>
                    <span>Heures totales depuis fabrication</span>
                  </v-tooltip>
                </v-col>
              <v-col cols="10" v-if="currentHours"
                ><span class="font-weight-bold font-italic red--text">{{
                  currentHours | formatMinutes
                }}</span></v-col
              >
              <v-col cols="10" v-else>Aucunes heures</v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2" >
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-airplane-landing</v-icon>
                  </template>
                  <span>cycles totales depuis fabrication</span>
                </v-tooltip>
              </v-col>
              <v-col cols="10" v-if="currentCycles"
                ><span class="font-weight-bold font-italic red--text">{{
                  currentCycles
                }}</span>
                cycles</v-col
              >
              <v-col cols="10" v-else>Pas de cycles</v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="2">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-numeric</v-icon>
                    </template>
                    <span>Position</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="10" v-if="aircraftPart.position"
                  >Position :
                  <span class="font-weight-bold font-italic primary--text" >{{
                    aircraftPart.position
                  }}</span></v-col
                >
                <v-col cols="10" v-else>Aucune position</v-col>
            </v-row>
          </v-col>
       
          <v-col cols="3">
            <v-row align="center" v-for="doc in aircraftPart.aircraftPartDocuments" :key="doc.id">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-file-pdf-box</v-icon>
                  </template>
                  <span>Document</span>
                </v-tooltip>
              </v-col>
              <v-col cols="10">
                <a :href="doc.documentAttachment.fileUrl" target="_blank">{{ doc.name }}</a>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="2">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-book-open-variant</v-icon>
                  </template>
                  <span>Manuels</span>
                </v-tooltip>
                <v-icon></v-icon>
              </v-col>
              <v-col cols="10" v-if="aircraftPart.manuals.length > 0" >
                <v-list-item-subtitle
                  v-for="manual in aircraftPart.manuals"
                  :key="manual.id"
                >
                  <router-link
                    :to="{ name: 'document', params: { id: manual.id } }"
                    >{{ manual.number }} - {{ manual.title }}</router-link
                  >
                </v-list-item-subtitle>
              </v-col>
              <v-col cols="10" v-else>Aucuns Manuels</v-col>
            </v-row>
          </v-col>
          <v-col cols="3">            
            <v-row align="center">
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" v-bind="attrs" v-on="on" >mdi-book-open-variant</v-icon>
                  </template>
                  <span>Notes</span>
                </v-tooltip>
              </v-col>
              <v-col cols="10" v-if="aircraftPart.notes"
                ><span class="font-italic">
                  {{ aircraftPart.notes }}</span
                ></v-col
              >
              <v-col cols="10" v-else>Aucune(s) Note(s)</v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-sheet>

    <v-col cols="12">
      <v-sheet outlined class="mt-6">
        <v-card-title>
          <item-stop-form
            :aircraftPartId="parseInt(aircraftPart.id, 10)"
            @change_item_stops="refreshItemStops()"
          ></item-stop-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="itemStops"
          :items-per-page="18"
          dense
          hide-default-footer
          no-results-text="Aucun résultat"
        >
          <!-- <template v-slot:item.stop.name="{ item }">
              <v-btn
                text
                block
                :to="{
                  name: 'ItemStop',
                  params: { id: item.id },
                }"
                >{{ item.serialNumber }}</v-btn
              >
            </template> -->
          <template v-slot:item.action="{ item }">
            <span>{{ item.action.name }} {{ item.additionalInfoFr }}</span>
          </template>
          <template v-slot:item.usedText="{ item }">
            <span v-for="v in item.usedText" :key="v['unit_code']"> {{ v['text'] }} <br /></span>
          </template>
          <template v-slot:item.remainingText="{ item }">
            <span v-for="v in item.remainingText" :key="v['unit_code']"> {{ v['text'] }} <br /></span>
          </template>
          <template v-slot:item.bouton="{ item }">
            <item-stop-form
              :itemStopId="parseInt(item.id, 10)"
              :aircraftPartId="parseInt(aircraftPart.id, 10)"
              @change_item_stops="refreshItemStops()"
            ></item-stop-form>
          </template>
          <template v-slot:item.rg="{ item }">
            <span v-if="item.globalCheckLink == true">Lié RG</span>
            <span v-if="item.hasDeviation == true" class="red--text font-weight-bold"> (D)</span>
          </template>
        </v-data-table>
      </v-sheet>
    </v-col>

    <v-col cols="12" v-if="$permissions.can(me, 'admin')">
      <v-sheet outlined class="mt-6">
          <v-list dense>
            <v-list-item>
              <v-list-item-title style="margin-bottom: 10px" class="font-weight-bold text-h6">HISTORIQUE
              </v-list-item-title>
              <v-list-item-action class="mr-4">
                <v-btn text outlined small> voir plus </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>

            <v-list-item v-for="change in aircraftPart.flyDataChanges" :key="change.id">
              <v-col cols="1" offset="1">
                <v-icon v-if="change.changeType == 'fly'">mdi-airplane-clock</v-icon>
                <v-icon v-if="change.changeType == 'work_order'">mdi-airplane-cog</v-icon>
                <v-icon v-if="change.changeType == 'mount'">mdi-airplane-plus</v-icon>
                <v-icon v-if="change.changeType == 'drop'">mdi-airplane-remove</v-icon>
              </v-col>
              <v-list-item-content>
                <v-col cols="11">
                  {{ change.dateChange | moment("DD/MM/YYYY") }} :
                  <span v-if="change.changeType == 'fly'">ajout de {{
                    showChange(change.dataChanges)
                    }}</span>
                  <span v-if="change.changeType == 'work_order'">réinitialisation de la butée : {{ change.itemStop.stop.name }}</span>
                  <span v-if="change.changeType == 'mount'">Création/Pose de la pièce</span>
                  <span v-if="change.changeType == 'drop'">Dépose de la pièce</span>
                </v-col>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-sheet>
        </v-col>

    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import { aircraftPartQuery } from "@/graphql/aircraft_part/aircraft_part";
import { deleteAircraftPartMutation } from "../../../graphql/aircraft_part/delete_aircraft_part";
import { itemStopsQuery } from "@/graphql/item_stop/item_stops";
import { meQuery } from "@/graphql/me";
import itemStopForm from "../../Butees/itemStopForm.vue";
import confirm from "@/components/confirm.vue";

export default {
  components: {
    "item-stop-form": itemStopForm,
    confirm,
  },
  data() {
    return {
      aircraftPart: null,
      ItemStops: [],
      headers: [
        { text: "", value: "bouton", width: "50px" },
        {
          text: "ATA",
          value: "aircraftPart.partModel.ata.chapter",
          width: "50px",
        },
        { text: "Butée", value: "stop.name" },
        { text: "", value: "rg" },
        { text: "Action", value: "action" },
        { text: "Pot. Effectué", value: "usedText", width: "250px" },
        { text: "Pot Reste", value: "remainingText", width: " 250px" },
      ],
    };
  },
  methods: {
    showChange(changes) {
      var buff = "";
      const hours = changes.find((f) => f.unit_code == "hours");
      if (hours) {
        buff += this.$options.filters.formatMinutes(hours["value"]);
      }
      const cycle = changes.find((f) => f.unit_code == "cycles");
      if (cycle) {
        buff +=  " / " + cycle["value"] + " cycles";
      }
      return buff;
    },
    refreshItemStops() {
      this.$apollo.queries.itemStops.refetch();
    },
    removePart() {
      this.$refs.confirm
        .open("Supprimer", "Êtes-vous sûr ?", { color: "red lighten-1" })
        .then((confirm) => {
          if (confirm) {
            this.$apollo
              .mutate({
                mutation: deleteAircraftPartMutation,
                variables: {
                  input: {
                    id: parseInt(this.aircraftPart.id, 10),
                  },
                },
              })
              .then(() => {
                this.$router.push({
                  name: "aeronef",
                  params: {
                    immatriculation: this.aircraftPart.aircraft.immatriculation,
                    page: 'pieces',
                  },
                });
              });
          }
        });
    },
  },
  computed: {
    initCycles() {
      if (this.aircraftPar && this.aircraftPart.flyValue["init"]) {
        const initC = this.aircraftPart.flyValue["init"].find(
          (f) => f.unit_code == "cycles"
        );
        if (initC) {
          return initC["value"];
        }
      }
      return null;
    },
    initHours() {
      if (this.aircraftPart && this.aircraftPart.flyValue["init"]) {
        const initC = this.aircraftPart.flyValue["init"].find(
          (f) => f.unit_code == "hours"
        );
        if (initC) {
          return initC["value"];
        }
      }
      return null;
    },
    currentCycles() {
      if (this.aircraftPart && this.aircraftPart.flyValue["current"]) {
        const initC = this.aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "cycles"
        );
        if (initC) {
          return initC["value"];
        }
      }
      return null;
    },
    currentHours() {
      if (this.aircraftPart) {
        const initC = this.aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "hours"
        );
        if (initC) {
          return initC["value"];
        }
      }
      return null;
    },
  },
  apollo: {
    aircraftPart: {
      query: aircraftPartQuery,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
    },
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftPartId: this.$route.params.id,
        };
      },
    },
    me: {
      query: meQuery,
    },
  },
};
</script>
