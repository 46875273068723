import gql from 'graphql-tag';

export const createTemplateAircraftMutation = gql`
  mutation createTemplateAircraft($input: CreateTemplateAircraftInput!) {
    createTemplateAircraft(input: $input) {
      templateAircraft {
        id
        name
      }
    }
  }`
