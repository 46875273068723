<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" v-if="aircraftPartDocument">
        <v-icon small color="red">mdi-close-thick</v-icon>
      </v-btn>
      <v-btn
        v-else
        small
        color="primary"
        dark
        v-bind="attrs"
        v-on="on"
        right
      >
        <v-icon>mdi-plus</v-icon>Ajouter un document</v-btn
      >
    </template>
    <v-card>
      <v-card-title class="headline text-uppercase">
        <div class="primary--text" v-if="aircraftPartDocument">
          Modifier le Document pour
          <span style="font-weight: bold; color: red">
            {{ aircraftPart.serialNumber }}
          </span>
        </div>
        <div class="primary--text" v-else>Nouveau document</div>
      </v-card-title>

      <v-card-text>
        <v-container>         
          <v-row>
            <v-col class="py-0">
              <v-text-field
                label="Nom"
                dense
                v-model="name"
              ></v-text-field>
            </v-col>
          </v-row>
           
          <v-row>
            <v-col>
              <dashboard :uppy="uppy" />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="cancel()"> Fermer </v-btn>
        <v-btn color="blue darken-1" text @click="save()"> Sauvegarder </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Dashboard } from "@uppy/vue";
import ActiveStorageUpload from "uppy-activestorage-upload";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import French from "@uppy/locales/lib/fr_FR";
import Uppy from "@uppy/core";
import { createAircraftPartDocumentMutation } from "../../../graphql/aircraft_part_document/create_aircraft_part_document";
import { updateAircraftPartDocumentMutation } from "../../../graphql/aircraft_part_document/update_aircraft_part_document";

export default {
  components: {
    Dashboard,
  },
  props: {
    aircraftPart: {
      Object,
      required: true,
    },
    aircraftPartDocument: Object,
  },
  data() {
    return {
      dialog: false,
      uploadedFileID: null,
      name: null,      
    };
  },
  methods: {
    cleanDialog() {
      this.name = null;
      this.uploadedFileID = null;      
      this.dialog = false;
      this.$emit("updateAircraftPartDoc");
    },
    save() {
      if (this.aircraftPartDocument) {
        this.$apollo
          .mutate({
            mutation: updateAircraftPartDocumentMutation,
            variables: {
              input: {
                id: parseInt(this.aircraftPartDocument.id, 10),
                attributes: {
                  aircraftPartId: this.aircraftPart.id,
                  name: this.name,
                  document: this.uploadedFileID,
                },
              },
            },
          })
          .then(() => {
            this.cleanDialog();
          });
      } else {
        this.$apollo
          .mutate({
            mutation: createAircraftPartDocumentMutation,
            variables: {
              input: {
                attributes: {
                  aircraftPartId: this.aircraftPart.id,
                  name: this.name,
                  document: this.uploadedFileID,
                },
              },
            },
          })
          .then(() => {
            this.cleanDialog();
          });
      }
    },

    cancel() {
      this.cleanDialog();
      this.dialog = false;
    },
  },
  computed: {    
    uppy: (self) =>
      new Uppy({
        logger: Uppy.debugLogger,
        autoProceed: true,
        allowMultipleUploads: false,
        inline: true,
        locale: French,
      })
        .use(ActiveStorageUpload, {
          directUploadUrl: process.env.VUE_APP_DIRECT_UPLOAD,
        })
        .on("complete", (result) => {
          console.log(
            "Upload complete! We’ve uploaded these files:",
            result.successful
          );

          self.uploadedFileID = result.successful[0].response.signed_id;
        }),
  },
  created() {    
    if (this.aircraftPartDocument) {
      this.name = this.aircraftPartDocument.name;
      this.uploadedFileID = this.aircraftPartDocument.documentAttachment.fileSignedId;
    }
  },
};
</script>
