var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',[(_vm.document)?_c('v-row',[_c('v-col',{attrs:{"cols":"1","align":"center"}},[_c('v-btn',{staticClass:"primary--text",attrs:{"icon":"","fab":"","outlined":"","x-small":"","link":"","to":"/documents"}},[_c('v-icon',[_vm._v("mdi-arrow-left-thick")])],1)],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-sheet',{style:(_vm.color),attrs:{"outlined":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[(_vm.document.type === 'Manual')?_c('v-chip',{attrs:{"dense":"","outlined":"","disable":"","small":"","color":"blue"}},[_vm._v("MANUEL")]):_c('v-chip',{attrs:{"dense":"","outlined":"","disable":"","small":"","color":"blue"}},[_vm._v("AD/SB")]),(_vm.document.mandatory === true || _vm.document.type === 'Ad')?[_c('v-chip',{attrs:{"dense":"","outlined":"","disable":"","small":"","color":"red"}},[_vm._v("MANDATORY")])]:_vm._e(),(_vm.document.recurring === true)?[_c('v-chip',{attrs:{"dense":"","outlined":"","disable":"","small":"","color":"green"}},[_vm._v("REPETITIVE")])]:_vm._e(),(
                    _vm.document.status === 'replace' ||
                    _vm.document.status === 'cancel'
                  )?[_c('v-chip',{attrs:{"dense":"","dark":"","disable":"","small":"","color":"red"}},[_vm._v("ANNULE")])]:_vm._e()],2),_c('v-col',{attrs:{"cols":"1","offset":"2"}},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2097855828)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":{
                        name: 'document_edit',
                        params: { id: _vm.document.id },
                      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-list-item-title',[_vm._v("Modifier")])],1),_c('v-list-item',{attrs:{"link":"","to":{
                        name: 'new_document',
                        query: { replace: _vm.document.id },
                      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-replace")])],1),_c('v-list-item-title',[_vm._v("Annuler et remplacer")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.openDialog()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-file-cancel-outline")])],1),_c('v-list-item-title',[_vm._v("Annuler")])],1)],1)],1)],1)],1)],1),(_vm.document.status === 'cancel')?_c('v-card-text',[_c('span',[_c('strong',[_vm._v("Cause d'annulation :")]),_vm._v(" "+_vm._s(_vm.document.cancelReason))])]):_vm._e(),(_vm.document.type !== 'Manual' && _vm.document.replaceBy)?_c('v-card-text',[_c('span',[_vm._v("Ce document est remplacé par "),_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":"red","to":{
                  name: 'document',
                  params: { id: _vm.document.replaceBy.id },
                }}},[_vm._v(_vm._s(_vm.document.replaceBy.numberRev)+" ")])],1)]):_vm._e(),(_vm.document.type == 'Manual' && _vm.document.replaceBy)?_c('v-card-text',[_c('span',[_vm._v("Ce manuel a une révision plus récente "),_c('v-btn',{attrs:{"outlined":"","rounded":"","small":"","color":"red","to":{
                  name: 'document',
                  params: { id: _vm.document.replaceBy.id },
                }}},[_vm._v(_vm._s(_vm.document.replaceBy.numberRev)+" ")])],1)]):_vm._e(),_c('v-card-title',{staticClass:"text-h5",attrs:{"text-align:justify":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.document.tcHolders.length > 0)?_vm._l((_vm.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id,staticStyle:{"margin-right":"10px"}},[_c('b',[_vm._v(_vm._s(tcHolder.name))])])}):_vm._e(),(_vm.document.type == 'Manual')?[_c('span',[_vm._v(_vm._s(_vm.document.number)+" ")]),_c('br'),_c('em',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.document.titleFr))])]:_c('span',[_c('b',[_vm._v(_vm._s(_vm.document.numberRev))]),_c('br'),_c('em',{staticStyle:{"word-break":"break-word"}},[_vm._v(_vm._s(_vm.document.titleFr))])])],2)],1)],1),_c('v-card-subtitle',[(_vm.document.type !== 'Manual')?[_c('em',{staticStyle:{"word-break":"break-word","font-size":"16px"}},[_vm._v(_vm._s(_vm.document.titleEn))])]:_vm._e()],2),_c('v-card-subtitle',[(_vm.document.type == 'Manual')?[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(_vm.document.version))])]:[_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" publié le "+_vm._s(_vm._f("moment")(_vm.document.publishingDate,"DD/MM/YYYY")))]),(_vm.document.type == 'Ad')?_c('span',{staticStyle:{"font-size":"14px"}},[_vm._v(" - Date effectif le "+_vm._s(_vm._f("moment")(_vm.document.effectiveDate,"DD/MM/YYYY")))]):_vm._e()],(_vm.document.sbLink.length > 0)?[_c('br'),_vm._v("Lié au "),_vm._l((_vm.document.sbLink),function(sb){return _c('v-chip',{key:sb.id,attrs:{"outlined":"","disable":"","small":""}},[_vm._v(_vm._s(sb.numberRev))])})]:_vm._e(),_c('br'),(_vm.document.type !== 'Manual' && _vm.document.replace)?[_c('span',[_vm._v("remplace "),_c('v-btn',{attrs:{"outlined":"","x-small":"","rounded":"","to":{
                    name: 'document',
                    params: { id: _vm.document.replace.id },
                  }}},[_vm._v(_vm._s(_vm.document.replace.numberRev)+" ")])],1)]:_vm._e(),_c('br'),(_vm.document.type == 'Manual' && _vm.document.replace)?[_c('span',[_vm._v("remplace "),_c('v-btn',{attrs:{"outlined":"","x-small":"","rounded":"","to":{
                    name: 'document',
                    params: { id: _vm.document.replace.id },
                  }}},[_vm._v(_vm._s(_vm.document.replace.number)+" "+_vm._s(_vm.document.replace.revision))])],1)]:_vm._e(),(_vm.document.additionalInformation)?[_c('span',[_vm._v("Note: "+_vm._s(_vm.document.additionalInformation))])]:_vm._e()],2),_c('v-card-subtitle',[(_vm.document.targetsModels.length > 0)?[_vm._v(" concerne : "),_vm._l((_vm.document.targetsModels),function(model){return _c('v-chip',{key:model.id,attrs:{"outlined":"","disable":""}},[_vm._v(_vm._s(model.tcHolder.name)+" - "+_vm._s(model.reference))])})]:_vm._e(),(_vm.document.targets['all'] == true)?[_c('v-chip',{attrs:{"outlined":"","disabled":""}},[_vm._v("Tous les Modèles")])]:_vm._e(),(
                _vm.document.targets['all_model_for_tc_holder'] == true &&
                _vm.document.targets['all'] == false
              )?[_c('v-chip',[_vm._v("Tous Modèles du TCHolder "),_vm._l((_vm.document.tcHolders),function(tcHolder){return _c('span',{key:tcHolder.id},[_vm._v(" "+_vm._s(tcHolder.name))])})],2)]:_vm._e()],2),_c('v-card-actions',{staticStyle:{"padding-left":"20px","padding-bottom":"20px"}},[(_vm.document.documentFile)?_c('v-btn',{staticClass:"red--text",attrs:{"align":"center","color":"white","outlined":"","href":_vm.document.documentFile,"target":"_blank"}},[_vm._v(" Consulter "),_c('v-icon',{attrs:{"right":"","color":"red"}},[_vm._v("mdi-file-pdf-box")])],1):_c('v-btn',{staticClass:"primary--text",attrs:{"align":"center","color":"white","outlined":"","disabled":""}},[_vm._v(" Non Disponible "),_c('v-icon',{staticClass:"primary--text",attrs:{"right":""}},[_vm._v("mdi-file-pdf-box")])],1),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteDocument(_vm.document)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1)],1)],1):_vm._e(),_c('confirm',{ref:"confirm"})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('div',[_c('p',{staticClass:"text-h5 primary--text"},[_vm._v("ANNULATION")]),_c('v-row',[_c('v-col',{staticClass:"mt-4",attrs:{"cols":"8","offset":"2"}},[_c('v-text-field',{attrs:{"label":"raison"},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}})],1)],1)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey","text":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v(" Fermer ")]),_c('v-btn',{attrs:{"color":"primary","text":"","loading":_vm.saveLoading},on:{"click":function($event){return _vm.cancelDocument(_vm.document)}}},[_vm._v(" Valider ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }