<template>
  <div :key="refreshCounter">
    <v-container v-if="!aircraft">
      <v-row>
        <v-progress-linear color="blue" indeterminate></v-progress-linear>
      </v-row>
    </v-container>
    <v-container v-else fluid style="width: 100%" color="background">
      <v-row>
        <!-- immat -->
        <v-col :cols="colsize1" style="width=300px">
          <v-sheet outlined min-height="813" justify-center>
            <v-img
              v-if="aircraft.photo"
              class="white--text align-end"
              :src="aircraft.photo"
              :aspect-ratio="16 / 9"
            >
            </v-img>
            <v-img
              v-else
              class="white--text align-end"
              height="160px"
              src="@/assets/Plane.png"
            ></v-img>
            <div>
              <v-card-title
                class="justify-center pa-0"
                v-if="aircraft.longMaintenance == true"
              >
                <v-icon class="mr-2" color="red">mdi-tools</v-icon>
                <span class="red--text">Maintenance longue</span>
              </v-card-title>
            </div>

            <v-card-text align="center" style="padding-bottom: 0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title
                    class="warning"
                    v-if="aircraft.status == 'archive'"
                  >
                    <p :class="classImmat">
                      {{ aircraft.immatriculation }}
                    </p>

                    <p class="text-subtitle-2 mt-0 pb-0">
                      Avion archivé le <br />
                      {{ aircraft.archivedAt | moment("DD/MM/YYYY") }}
                    </p>
                    <p
                      class="text-caption mt-0 pb-0"
                      v-for="arc in aircraft.archiveReasons"
                      :key="arc.id"
                    >
                      {{ reasonList.find((r) => r.value == arc.reason).text }}
                      ({{ arc.createdAt | moment("DD/MM/YYYY") }}) :
                      <span v-html="arc.details"></span>
                    </p>
                  </v-list-item-title>
                  <v-list-item-title class="" v-if="aircraft.status == 'init'">
                    <p :class="classImmat">
                      {{ aircraft.immatriculation }}
                    </p>
                  </v-list-item-title>
                  <v-list-item-title v-else>
                    <p :class="classImmat">{{ aircraft.immatriculation }}</p>
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="font-weight-bold text-h6"
                    v-if="aircraft.cell.manufacturer"
                  >
                    <span class="font-italic text-caption mb-0">
                      {{ aircraft.cell.manufacturer.name }}
                    </span>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle
                    class="font-weight-bold text-h6"
                    v-if="aircraft.cell.manufacturingDate"
                  >
                    <span class="font-italic text-caption mb-0"
                      >Fab :
                      {{
                        aircraft.cell.manufacturingDate | moment("DD/MM/YYYY")
                      }}</span
                    >
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-else>
                    <span class="nonrens">Pas de date de fabrication</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>

            <v-list-item three-line raised class="text-center">
              <v-list-item-content>
                <v-list-item-title :class="classPiece">
                  {{ aircraft.cell.partModel.tcHolder.name }}<br />
                  {{ aircraft.cell.partModel.reference }}<br />
                  <span class="font-weight-medium font-italic text-subtitle-2"
                    >(S/N: {{ aircraft.cell.serialNumber }})</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle :class="classPiece2" v-if="!aircraft.cell.noTotalHours" 
                  >HT:
                  <span class="font-weight-black" style="color: #2979ff">
                    {{ showHours(aircraft.cell) }}</span
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="classPiece2" v-else
                  >HT inconnues
                  
                </v-list-item-subtitle>

                <v-list-item-subtitle :class="classPiece2" v-if="showCycles(aircraft.cell) != '0'">
                  Cycles: {{ showCycles(aircraft.cell) }}
                </v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>

            <v-list-item
              three-line
              raised
              class="text-center mt-0"
              v-for="motor in aircraft.motors"
              :key="motor.id"
            >
              <v-list-item-content>
                <v-list-item-title :class="classPiece">
                  {{ motor.partModel.tcHolder.name }}<br />
                  {{ motor.partModel.reference }}<br />
                  <span class="font-weight-medium font-italic text-subtitle-2"
                    >(S/N: {{ motor.serialNumber }})</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle :class="classPiece2" v-if="!motor.noTotalHours"
                  >HT:
                  <span class="font-weight-black" style="color: #2979ff">{{
                    showHours(motor)
                  }}</span>
                   </v-list-item-subtitle>                
                <v-list-item-subtitle :class="classPiece2" v-else
                  >HT inconnues
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="classPiece2" v-if="motor.rgHours">
                  HRG:
                  <span class="font-weight-black" style="color: #2979ff">{{
                    showHoursCalc(motor.rgHours)
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="classPiece2" v-if="showCycles(motor) != '0'">
                  Cycles: {{ showCycles(motor) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              v-if="
                aircraft.motors.length < aircraft.cell.partModel.numberMotor
              "
            >
              <v-list-item-content align="center">
                <v-btn
                  class="primary--text"
                  dark
                  outlined
                  :to="{
                    name: 'aeronef_part_new',
                    params: { immatriculation: aircraft.immatriculation },
                    query: { type: 'Motor' },
                  }"
                  >Ajout Moteur</v-btn
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              three-line
              raised
              class="text-center mt-0"
              v-for="propeller in aircraft.propellers"
              :key="propeller.id"
            >
              <v-list-item-content>
                <v-list-item-title :class="classPiece">
                  {{ propeller.partModel.tcHolder.name }}<br />
                  {{ propeller.partModel.reference }}<br />
                  <span class="font-weight-medium font-italic text-subtitle-2"
                    >(S/N: {{ propeller.serialNumber }})</span
                  >
                </v-list-item-title>
                <v-list-item-subtitle :class="classPiece2" v-if="!propeller.noTotalHours"
                  >HT inconnues</v-list-item-subtitle>                
                <v-list-item-subtitle :class="classPiece2" v-else
                  >HT:
                  <span class="font-weight-black" style="color: #2979ff">{{
                    showHours(propeller)
                  }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="classPiece2" v-if="propeller.rgHours">
                  HRG:
                  <span class="font-weight-black" style="color: #2979ff">
                    {{
                    showHoursCalc(propeller.rgHours)
                  }}
                  </span>
                </v-list-item-subtitle>
                <v-list-item-subtitle :class="classPiece2" v-if="showCycles(propeller) != '0'">
                  Cycles: {{ showCycles(propeller) }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-if="
                aircraft.propellers.length < aircraft.cell.partModel.numberMotor
              "
            >
              <v-list-item-content>
                <v-btn
                  class="primary--text"
                  dark
                  outlined
                  :to="{
                    name: 'aeronef_part_new',
                    params: { immatriculation: aircraft.immatriculation },
                    query: { type: 'Propeller' },
                  }"
                  >Ajout Hélice</v-btn
                >
              </v-list-item-content>
            </v-list-item>
          </v-sheet>
        </v-col>
        <!-- tabs -->
        <v-col :cols="colsize2" style="min-width: 100px; max-width: 100%">
          <v-tabs
            v-model="page"
            background-color="background"
            color="titre"
            centered
            center-active
            fixed-tabs
            show-arrows
            icons-and-text
          >
            <v-tabs-slider color="titre"></v-tabs-slider>
            <v-tab href="#info">
              Infos
              <v-icon>mdi-folder-information</v-icon>
            </v-tab>
            <v-tab href="#situation">
              Situation
              <v-icon>mdi-folder-text-outline</v-icon>
            </v-tab>
            <v-tab href="#ot">
              OT
              <v-icon>mdi-progress-wrench</v-icon>
            </v-tab>
            <v-tab href="#hdv">
              HdV
              <v-icon>mdi-clock-outline</v-icon>
            </v-tab>
            <v-tab href="#ad">
              AD
              <v-badge
                color="accent"
                :content="AdToProcess"
                :value="AdToProcess > 0"
              >
                <v-icon>mdi-book-open-variant</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#sb">
              SB
              <v-badge
                color="accent"
                :content="SbToProcess"
                :value="SbToProcess > 0"
              >
                <v-icon>mdi-book-open-variant</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#pieces">
              Pièces
              <v-icon>mdi-tools</v-icon>
            </v-tab>
            <v-tab href="#doc">
              Doc
              <v-icon>mdi-file-image-outline</v-icon>
            </v-tab>
            <v-tab href="#stc">
              STC
              <v-badge
                color="success"
                :content="aircraftCustomizations.length"
                :value="aircraftCustomizations.length > 0"
              >
                <v-icon>mdi-airplane-cog</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#mb303">
              MB303
              <v-badge
                color="success"
                :content="aircraftMb303s.length"
                :value="aircraftMb303s.length > 0"
              >
                <v-icon>mdi-airplane-search</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#incidents">
              Incidents
              <v-badge
                color="success"
                :content="aircraftIncidents.length"
                :value="aircraftIncidents.length > 0"
              >
                <v-icon>mdi-airplane-alert</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#deviation">
              Déviation
              <v-badge
                color="success"
                :content="itemStops.length"
                :value="itemStops.length > 0"
              >
                <v-icon>mdi-airplane-plus</v-icon>
              </v-badge>
            </v-tab>
            <v-tab href="#photo">
              Photo
              <v-icon>mdi-camera</v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="page">
            <v-tab-item value="info">
              <!-- information gestion -->
              <InfoGestion @refreshPage="refreshPage()" />
            </v-tab-item>
            <v-tab-item value="situation">
              <!-- situation technique -->
              <GestionButee :aircraftId="aircraft.id" />
            </v-tab-item>
            <v-tab-item value="ot">
              <!-- Ordres de travail -->
              <dataTableOT :aircraft="aircraft" />
            </v-tab-item>
            <v-tab-item value="hdv">
              <!-- Heures de vol -->
              <dataTableHeureVol :aircraft="aircraft" />
            </v-tab-item>
            <v-tab-item value="ad">
              <!-- AD -->
              <v-row flex>
                <v-col fluid>
                  <dataTableTraitement
                    typeDoc="Ad"
                    :aircraftId="parseInt(aircraft.id, 10)"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="sb">
              <!-- SB -->
              <v-row flex>
                <v-col fluid>
                  <dataTableTraitement
                    typeDoc="Sb"
                    :aircraftId="parseInt(aircraft.id, 10)"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="pieces">
              <!-- Pièces -->
              <data-table-piece :aircraftId="aircraft.id" />
            </v-tab-item>
            <v-tab-item value="doc">
              <!-- docu de vol -->
              <dataTableDocuVol :aircraft="aircraft" />
            </v-tab-item>
            <v-tab-item value="stc">
              <dataTableSTC :aircraftId="aircraft.id"></dataTableSTC>
            </v-tab-item>
            <v-tab-item value="mb303">
              <dataTableMb303 :aircraft="aircraft"></dataTableMb303>
            </v-tab-item>
            <v-tab-item value="incidents">
              <dataTableIncident :aircraft="aircraft"></dataTableIncident>
            </v-tab-item>
            <v-tab-item value="deviation">
              <dataTableDev :aircraft="aircraft"></dataTableDev>
            </v-tab-item>
            <v-tab-item value="photo">
              <dataTablePhoto :aircraft="aircraft"></dataTablePhoto>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style scoped>
.text-h7 {
  font-size: 18px;
}

.text-h8 {
  font-size: 42px;
}

.text-subtitle-3 {
  font-size: 16px;
}

.v-list-item__content {
  justify-content: center;
}

.v-tab {
  margin: 10px 0 0 0;
}
.nonrens {
  font-style: italic;
  color: red;
}
</style>

<script>
import { aircraftQuery } from "../../graphql/aircraft/aircraft";
import { documentProcessingsQuery } from "../../graphql/document_processing/document_processings";
import { aircraftMb303sQuery } from "../../graphql/aircraft_mb303/aircraft_mb303s";
import { aircraftCustomizationsQuery } from "../../graphql/aircraft_customization/aircraft_customizations";
import { aircraftIncidentsQuery } from "../../graphql/aircraft_incident/aircraft_incidents";
import { itemStopsQuery } from "../../graphql/item_stop/item_stops";
import InfoGestion from "./InfoGestion/InfoGestion.vue";
import GestionButee from "./Butees/GestionButee.vue";
import dataTableDocuVol from "./DocuVol/dataTableDocuVol.vue";
import dataTablePiece from "./Part/dataTablePieces.vue";
import dataTableHeureVol from "./HeureVol/dataTableHeureVol.vue";
import dataTableOT from "./OT/dataTableOT.vue";
import dataTableTraitement from "../Traitement/dataTableTraitement.vue";
import dataTableSTC from "./stc/dataTableSTC.vue";
import dataTableMb303 from "./mb303/dataTableMb303.vue";
import dataTablePhoto from "./photo/dataTablePhoto.vue";
import dataTableDev from "./deviation/dataTableDev.vue";
import dataTableIncident from "./incident/dataTableIncident.vue";

export default {
  name: "Aeronef",
  components: {
    InfoGestion,
    GestionButee,
    dataTableDocuVol,
    dataTablePiece,
    dataTableHeureVol,
    dataTableOT,
    dataTableTraitement,
    dataTableSTC,
    dataTableMb303,
    dataTablePhoto,
    dataTableDev,
    dataTableIncident,
  },
  metaInfo() {
    return {
      title: this.$route.params.immatriculation,
    };
  },
  data() {
    return {
      aircraft: null,
      refreshCounter: 0,
      aircraftCustomizations: [],
      aircraftMb303s: [],
      aircraftIncidents: [],
      itemStops: [],
      reasonList: [
        { text: "Avion Cassé", value: "broken" },
        { text: "Avion vendu", value: "sell" },
        {
          text: "Abonnement non renouvellé par client",
          value: "no_renew_client",
        },
        {
          text: "Abonnement non renouvellé par sega",
          value: "no_renew_sega",
        },
        { text: "Contrat résilié par client", value: "cancel_client" },
        { text: "Contrat résilié par sega", value: "cancel_sega" },
        { text: "Autres", value: "other" },
      ],
    };
  },
  methods: {
    showHours(aircraftPart) {
      if (aircraftPart.flyValue["current"]) {
        const flyValue = aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "hours"
        );
        var str = "";
        if (flyValue) {
          str += Math.floor(flyValue.value / 60);
          str += " h ";
          str += flyValue.value % 60;
        }
        return str;
      } else {
        return "";
      }
    },
    showCycles(aircraftPart) {
      if (aircraftPart.flyValue["current"]) {
        const flyValue = aircraftPart.flyValue["current"].find(
          (f) => f.unit_code == "cycles"
        );
        var str = "";
        if (flyValue) {
          str = flyValue.value;
        }
        return str;
      } else {
        return "";
      }
    },
    showHoursCalc(hours) {
      var str = "";
      str += Math.floor(hours / 60);
      str += " h ";
      str += hours % 60;
      return str;
    },
    refreshPage() {
      this.$apollo.queries.aircraft.refetch();
    },
  },
  computed: {
    classImmat() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "text-h5 primary--text font-weight-bold mb-0 pt-2";
        case "sm":
          return "text-h5 primary--text font-weight-bold mb-0 pt-2";
        case "md":
          return "text-h5 primary--text font-weight-bold mb-0 pt-2";
        case "lg":
          return "text-h4 primary--text font-weight-bold mb-0 pt-2";
        case "xl":
          return "text-h3 primary--text font-weight-bold mb-0 pt-2";
      }
      return "text-h3 primary--text font-weight-bold mb-0 pt-2";
    },
    classPiece: function () {
      if (this.aircraft.motors.length > 1) {
        return "font-weight-bold text-h7 ";
      } else {
        return "font-weight-bold text-h6 pb-2";
      }
    },
    classPiece1: function () {
      if (this.aircraft.motors.length > 1) {
        return "text-h8 font-weight-bold";
      } else {
        return "text-h3 font-weight-bold";
      }
    },
    classPiece2: function () {
      if (this.aircraft.motors.length > 1) {
        return "font-weight-medium font-italic text-subtitle-3";
      } else {
        return "font-weight-medium font-italic text-subtitle-2";
      }
    },
    page: {
      set(page) {
        if (this.$route.params.page != page) {
          this.$router.replace({ params: { ...this.$route.query, page } });
        }
      },
      get() {
        return this.$route.params.page;
      },
    },
    AdToProcess: function () {
      if (!this.documentProcessings || this.documentProcessings.length == 0) {
        return null;
      }

      var i = 0;
      this.documentProcessings.forEach((doc) => {
        if (doc.process === null && doc.document.type == "Ad") {
          i += 1;
        }
      });
      return i;
    },
    SbToProcess: function () {
      if (!this.documentProcessings || this.documentProcessings.length == 0) {
        return null;
      }

      var i = 0;
      this.documentProcessings.forEach((doc) => {
        if (doc.process === null && doc.document.type == "Sb") {
          i += 1;
        }
      });
      return i;
    },
    colsize1() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 3;
        case "sm":
          return 3;
        case "md":
          return 3;
        case "lg":
          return 2;
        case "xl":
          return 2;
      }
      return "2";
    },
    colsize2() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 9;
        case "sm":
          return 9;
        case "md":
          return 9;
        case "lg":
          return 10;
        case "xl":
          return 10;
      }
      return "4";
    },

    // HdvToProcess: function () {
    //   if (!this.fliesToValidated || this.fliesToValidated.length == 0) {
    //     return null;
    //   }

    //   var i = 0;
    //   this.fliesToValidated.forEach((doc) => {
    //     if (doc.process === null && doc.document.type == "Sb") {
    //       i += 1;
    //     }
    //   });
    //   return i;
    // },
  },
  apollo: {
    aircraft: {
      query: aircraftQuery,
      variables() {
        return {
          immatriculation: this.$route.params.immatriculation,
        };
      },
    },
    documentProcessings: {
      query: documentProcessingsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          status: "to_process",
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
    aircraftMb303s: {
      query: aircraftMb303sQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
    aircraftCustomizations: {
      query: aircraftCustomizationsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
    aircraftIncidents: {
      query: aircraftIncidentsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
    itemStops: {
      query: itemStopsQuery,
      variables() {
        return {
          aircraftId: this.aircraft.id,
          hasDeviation: true,
        };
      },
      skip() {
        return !this.aircraft;
      },
    },
  },
};
</script>
