var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"mb-8 mt-8"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',[_c('v-dialog',{attrs:{"max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){_vm.dialog_upload = !_vm.dialog_upload}}},on),[_c('v-icon',[_vm._v("mdi-plus-circle-outline")])],1),_c('div',{staticClass:"mt-2"},[_vm._v(" Ajouter des tâches de maintenance ")])]}}]),model:{value:(_vm.dialog_upload),callback:function ($$v) {_vm.dialog_upload=$$v},expression:"dialog_upload"}},[_c('v-card',[_c('v-card-title',[_vm._v("Ajouter des élements à :")]),_c('v-card-subtitle',[_vm._v("Maintenance alternatives aux exigences du DAH")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{ outlined: true },"toolbar-attributes":{
                      dense: true,
                      color: '#eceff1',
                    },"placeholder":"Pièce concernée"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{ outlined: true },"toolbar-attributes":{
                      dense: true,
                      color: '#eceff1',
                    },"placeholder":"Tâche ou inspection recommandé"},model:{value:(_vm.task),callback:function ($$v) {_vm.task=$$v},expression:"task"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{ outlined: true },"toolbar-attributes":{
                      dense: true,
                      color: '#eceff1',
                    },"placeholder":"Intervalle recommandé"},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{ outlined: true },"toolbar-attributes":{
                      dense: true,
                      color: '#eceff1',
                    },"placeholder":"Tâche ou inspection alternative"},model:{value:(_vm.task_alt),callback:function ($$v) {_vm.task_alt=$$v},expression:"task_alt"}})],1),_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('tiptap-vuetify',{attrs:{"extensions":_vm.extensions,"card-props":{ outlined: true },"toolbar-attributes":{
                      dense: true,
                      color: '#eceff1',
                    },"placeholder":"Intervalle alternatif"},model:{value:(_vm.interval_alt),callback:function ($$v) {_vm.interval_alt=$$v},expression:"interval_alt"}})],1)],1),_c('v-row',[(_vm.itemId)?_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.updateData(_vm.itemId)}}},[_vm._v(" Modifier ")])],1):_c('v-col',{attrs:{"cols":"12","align":"center"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.addData()}}},[_vm._v(" Ajouter ")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-simple-table',[_c('thead',[_c('tr',[_c('th',[_vm._v("Pièce concerné")]),_c('th',[_vm._v("Tâche ou inspection")]),_c('th',[_vm._v("Intervalle recommandé")]),_c('th',[_vm._v("Tâche ou inspection alternative")]),_c('th',[_vm._v("Intervalle alternatif")]),_c('th',{staticStyle:{"width":"110px"}})])]),_c('tbody',_vm._l((_vm.maintenanceProgrammeItems),function(d){return _c('tr',{key:d.id},[_c('td',{domProps:{"innerHTML":_vm._s(d.description)}}),_c('td',{domProps:{"innerHTML":_vm._s(d.task)}}),_c('td',{domProps:{"innerHTML":_vm._s(d.interval)}}),_c('td',{domProps:{"innerHTML":_vm._s(d.taskAlt)}}),_c('td',{domProps:{"innerHTML":_vm._s(d.intervalAlt)}}),_c('td',[_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){return _vm.editData(d.id)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{staticClass:"mb-4",attrs:{"icon":""},on:{"click":function($event){return _vm.removeData(d)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-delete")])],1)],1)])}),0)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }